@import "./mixin.scss";

// font family
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

// default colors
:root {
  // primary colors
  --blue: #00b;
  --secondary: #003399;
  --primary: #f05a28;
  --light-blue: #2082ca;
  --dark-blue: #29256f;

  // Hover colors
  --secondary-hover: #e24410;
  --primary-hover: #194bed;
  --black: #2b283f;
  --white: #fff;
  --gray: #e0dfdc;

  // tag color
  --tag: #0096b7;
  --tag-bg: #d5f7f7;
  --tag-error: #fcdfe6;

  // Line
  --line: #ebecf3;
  --dark-line: #d6d6d6;

  // bakground
  --site-background: #f9fafc;
  --footer: #11132a;

  // error colro
  --error: #ea2b59;

  // success message
  --success: #05cd99;

  // infobox
  --info-box: #fef6e5;

  // font-family
  --inter: "Inter", sans-serif;

  // typography
  // --text: #232337;
  --text: #1c2041;
  // --text-second: #535768;
  --text-second: #5a6183;
  --text-blue: #817ce4;

  // bg colors
  --light-gray: #ececec;
  --light-blue: #ebf6ff;

  // border
  --contact-border: #e6ecff;
  --border: #d9d9d9;

  // font-weight
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: var(--inter) !important;
  font-weight: normal;
  font-size: 16px;
  color: var(--text);
  line-height: 1.7em;
}
a,
a:hover {
  text-decoration: none;
}

p,
ul,
li,
ol,
:where(.css-dev-only-do-not-override-j9bb5n).ant-col {
  font-family: var(--inter) !important;
}
:where(.css-dev-only-do-not-override-j9bb5n).ant-col {
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--inter);
  font-weight: var(--bold);
  line-height: 177%;
}

img {
  @include imageSelectnone;
}

// heading tags with font size
h1 {
  font-size: 30px;
  line-height: 43px;
  @media (max-width: 800px) {
    font-size: 23px;
    line-height: 32px;
  }
}

h2 {
  font-size: 25px;
  line-height: 38px;
  @media (max-width: 800px) {
    font-size: 22px;
    line-height: 30px;
  }
}

h3 {
  font-size: 24px;
  line-height: 39px;
  @media (max-width: 800px) {
    font-size: 23px;
    line-height: 36px;
  }
}

h4 {
  line-height: 28px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
}

// text colors
.text-gray {
  color: var(--text-second);
}
.text-blue {
  color: var(--text-blue);
}
.text-error {
  color: var(--error);
}

.text-black {
  color: var(--text);
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}

// text-ellipse ===========
.text-ellipse1 {
  @include textEllipse($line: 1);
}
.text-ellipse2 {
  @include textEllipse($line: 2);
}
.text-ellipse3 {
  @include textEllipse($line: 3);
}
// border design ===========
.border-radius-20 {
  border-radius: 20px;
}
.padding-left {
  padding-left: 3rem;
}
.border {
  border: 1px solid var(--line);
}
// font-size================
.heading-1 {
  @include heading1;
}
.font-1 {
  @include font1;
}

.font-2 {
  @include font2;
}

.font-3 {
  @include font3;
}

.font-4 {
  @include font4;
}

.font-5 {
  @include font5;
}

.font-6 {
  @include font6;
}
// cursor pointer
.cursor-pointer {
  cursor: pointer;
}
// font-weight
.font-weight-medium {
  font-weight: var(--medium);
}
.font-weight-semibold {
  font-weight: var(--semibold);
}
.font-weight-bold {
  font-weight: bold;
}

// z-index =====================
.z-index {
  z-index: 1;
}
.z-index-0 {
  z-index: 0;
}
// spacing ======================
.spacing-large {
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: 500px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.spacing {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: 500px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.spacing-top {
  padding-top: 4rem;
  @media (max-width: 500px) {
    padding-top: 3rem;
  }
}
.spacing-bottom {
  padding-bottom: 4rem;
  @media (max-width: 500px) {
    padding-bottom: 3rem;
  }
}
a {
  text-decoration: none;
  background-color: transparent;
  color: var(--primary);
}
a:hover {
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
img {
  /* --Drag-- */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;

  // user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
/* --Header style starts here-- */
.main-navigation {
  background: var(--white);
  border-bottom: 1px solid #f0f1f2;
  border-radius: 0;
  display: block;
  left: 0;
  margin-bottom: 0;
  min-height: 60px;
  padding: 9px 20px;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 9999;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .brand {
    a {
      color: #337ab7;
      display: block;
    }
    img {
      height: 36px;
    }
    link {
      color: #337ab7;
      display: block;
    }
  }
  .right-menu {
    float: right;
  }
  /* --Center Menu starts-- */
  .center-menu {
    line-height: 0;
    @media (max-width: 992px) {
      display: none;
      position: absolute;
      top: 58px;
      background: var(--white);
      left: 0;
      padding: 19px;
    }
    a {
      text-decoration: none;
    }
    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding-left: 0;
      li {
        display: block;
        float: left;
        position: relative;
        text-decoration: none;
        &:last-child {
          a {
            padding-right: 0;
          }
        }
        a {
          color: var(--text);
          display: block;
          font-weight: 600;
          padding: 7px 11px;
          text-transform: capitalize;
          @media (max-width: 992px) {
            margin-bottom: 20px;
          }
          i {
            left: 5px;
            position: relative;
            top: 1px;
          }
        }
        ul {
          background: var(--white);
          -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
          display: none;
          left: 0;
          margin-top: 10px;
          min-width: 18rem;
          opacity: 0;
          position: absolute;
          transition: all 0.5s ease;
          visibility: hidden;
          &::before {
            color: #262626;
            content: "\f0de";
            font-family: FontAwesome;
            font-size: 35px;
            font-style: normal;
            font-weight: normal;
            left: 10%;
            padding-right: 0.5em;
            position: absolute;
            text-decoration: inherit;
            top: -19px;
          }
          li {
            clear: both;
            padding: 0;
            text-align: left;
            width: 100%;
            &:last-child {
              a {
                border-bottom: 0;
              }
            }
            &:hover {
              a {
                background: #fbfbfb;
              }
            }
            &.active {
              a {
                color: var(--primary);
              }
            }
            a {
              border-bottom: 1px solid #ebebeb;
              color: rgb(48, 48, 48);
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
  .right-action-button {
    float: right;
    line-height: 46px;
    @media (max-width: 900px) {
      display: none;
    }
    a {
      background: var(--primary);
      border-radius: 5px;
      color: var(--white);
      font-size: 15px;
      font-weight: 600;
      line-height: 29px;
      padding: 10px 16px;
    }
  }
  &.inner-nav {
    ul {
      li {
        &.active {
          a {
            color: var(--primary);
          }
        }
        a {
          color: rgb(69, 69, 69);
          &:hover {
            color: var(--primary);
          }
        }
      }
    }
  }
  /* --right-menu starts-- */
  .right-menu {
    padding: 10px 0;
    ul {
      float: left;
      margin-bottom: 0;
      width: 100%;
      li {
        float: left;
        list-style: none;
        position: relative;
        &:first-child {
          display: none;
        }
        a {
          outline: none;
          padding: 10px 8px;
          text-decoration: none;
          i {
            color: var(--white);
            font-size: 20px;
          }
          &:first-child {
            color: var(--white);
            font-weight: 600;
            img {
              height: 20px;
              margin-right: 3px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.main-navigation .center-menu ul li:hover > ul,
.main-navigation .center-menu ul li ul:hover {
  display: block;
  opacity: 1;
  visibility: visible;
}

.main-navigation ul li a:hover {
  color: var(--primary);
}
.main-navigation ul li.active ul li a,
.main-navigation.inner-nav ul li.active ul li a,
.main-navigation.inner-nav ul li ul li a:hover,
.main-navigation.inner-nav ul li ul li a {
  color: rgb(48, 48, 48);
}

/* --Center Menu starts-- */

.main-navigation.inner-nav .right-menu ul li a,
.main-navigation.inner-nav .right-menu ul li a i {
  color: rgb(48, 48, 48);
}

/* --right-menu ends-- */

/* --sticky navigation starts starts-- */
.main-navigation.sticky {
  background: var(--white);
  min-height: 40px;
  padding: 6px 20px;
  position: fixed;
  top: 0;

  // box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  width: 100%;
  z-index: 99;
}

.main-navigation.inner-nav .white-logo {
  display: none;
}

.main-navigation.inner-nav .dark-logo {
  display: block;
}

.main-navigation.sticky .right-menu ul li a,
.main-navigation.sticky .search-button a i,
.main-navigation.sticky .center-menu ul li a,
.main-navigation.sticky .right-menu ul li a i {
  color: #333;
}

.main-navigation.sticky ul li a:focus,
.main-navigation ul li a:focus,
.main-navigation.sticky ul li a:hover,
.main-navigation ul li a:hover,
.main-navigation.sticky ul li.active a,
.main-navigation.sticky.inner-nav ul li.active a {
  color: var(--primary);
}

.main-navigation.sticky ul li ul li a:focus,
.main-navigation.sticky ul li ul li a:hover {
  color: rgb(69, 69, 69);
}

.main-navigation .dark-logo {
  display: none;
}

.main-navigation.sticky .white-logo {
  display: none;
}

.main-navigation.sticky .dark-logo {
  display: block;
}

/* --sticky navigation ends starts-- */

/* --Header style ends here-- */

/* --Header style ends here-- */

a.btn {
  background-color: transparent;
  border: 2px solid;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  padding: 0 18px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.3s ease 0s, border 0.3s ease 0s, color 0.3s ease 0s,
    box-shadow 0.3s ease 0s;
  user-select: none;
  vertical-align: top;
}

a.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

a.btn-secodnary {
  border-color: #888;
  color: #555;
}

a.btn i.fa {
  background: var(--white);
  border-radius: 50%;
  color: #133a50;
  font-family: FontAwesome;
  margin-left: 10px;
  padding: 10px;
}

a.btn:hover {
  background-color: #9ce;
  border-color: #9ce !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff !important;
}

/* --Slider image style-- */
.slider-image {
  width: 100%;
}

.slider-image img {
  display: block;
  margin: 0 auto;
  width: 94%;
}

/* --Main Slider Style ends-- */

/* --Document IT fun facts section starts-- */

.heading {
  position: relative;
  z-index: 9;
}

.heading h2,
.heading span {
  text-align: center;
}

.heading span {
  color: var(--blue);
  display: block;

  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.heading h2 {
  color: #0f111a;
  font-size: 30px;
  line-height: 150%;
  margin-bottom: 30px;
  position: relative;
}

// .heading h2:before,
// .heading h2:after {
//   width: 82px;
//   height: 3px;
//   content: "";
//   position: absolute;
//   border: 2px solid #fab455;
//   border-radius: 5px;
// }
// .heading h2:before {
//   bottom: -18px;
//   left: 44%;
// }
// .heading h2:after {
//   bottom: -30px;
//   left: 48%;
// }

/* --Heading-- */

.fun-facts {
  background: #f4f5f8;
  color: #fafcfe;
  float: left;
  padding: 90px 0;
  width: 100%;
  .list {
    background-color: var(--white);
    background-image: url("/assets/images/circle.png");
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
    float: left;
    margin-top: 30px;
    padding: 35px 15px;
    text-align: center;
    width: 100%;
    h3 {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      color: #6a7695;
      font-size: 17px;
      text-transform: capitalize;
    }
  }

  .col-lg-3:nth-child(3) .list h3,
  .col-lg-4:nth-child(3) .list h3 {
    color: #2bafe5;
  }

  .col-lg-3:nth-child(2) .list h3,
  .col-lg-3:nth-child(4) .list h3,
  .col-lg-4:nth-child(2) .list h3,
  .col-lg-4:nth-child(4) .list h3 {
    color: #fbb029;
  }

  .col-lg-3:nth-child(5) .list h3,
  .col-lg-4:nth-child(5) .list h3 {
    color: #3bba94;
  }

  .col-lg-3:last-child .list h3,
  .col-lg-4:last-child .list h3 {
    color: #8259a5;
  }
}

/* --Document IT fun facts section ends-- */

/* --testimonials starts-- */
.testimonials {
  background-color: var(--primary);
  float: left;
  padding: 90px 0 97px;
  position: relative;
  width: 100%;
  .testimonials-content,
  .testimonials-image {
    float: left;
    text-align: left;
    width: 100%;
  }
  .testimonials-content {
    span {
      color: var(--white);
      display: block;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    h3 {
      color: var(--white);
      display: block;
      font-size: 32px;
      margin-bottom: 20px;
      @media (max-width: 500px) {
        font-size: 25px;
      }
    }
    .customer-message {
      background: var(--white);
      border-radius: 5px;
      min-height: 188px;
      padding: 25px;
      position: relative;
      &::after {
        bottom: -18px;
        color: var(--white);
        content: "\f0d7";
        font-family: FontAwesome;
        font-size: 29px;
        font-style: normal;
        font-weight: normal;
        left: 27px;
        padding-right: 0.5em;
        position: absolute;

        /* right: 0; */
        text-align: center;
        text-decoration: inherit;
      }
    }
    .testimonials-list {
      float: left;
      list-style: none;
      padding: 0;
      width: 100%;
      .details {
        display: block;
        margin-top: 20px;
        position: relative;
        &:hover,
        &:focus,
        &:active {
          outline: none;
        }
      }
      .profile {
        margin-top: 20px;
        h5 {
          color: var(--white);

          font-weight: 600 !important;
        }
      }
    }
  }
}

.testimonials .testimonials-content .testimonials-list .details img {
  border-radius: 50%;
  display: none;
  height: 50px;

  /* float: left; */
  margin: 0 auto;
  width: 50px;
}

.testimonials .testimonials-content .testimonials-list .details p {
  font-size: 17px;
  line-height: 180%;
}

.testimonials .testimonials-content .list {
  padding: 0 10px;
}

.testimonials .testimonials-content .testimonials-list .details h4 {
  color: var(--white);

  margin-top: 15px !important;
}

.testimonials .testimonials-content ul.testimonials-list li h5 {
  font-size: 19px;
  margin-top: 10px;
}

.testimonials .testimonials-image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 80%;
}

.testimonials .testimonials-content .testimonials-controls {
  float: left;
  margin-top: 49px;
  text-align: left;
  width: 100%;
}

.testimonials .testimonials-content .testimonials-controls a {
  border: none;
  color: var(--primary);
  display: inline-block;
  font-size: 28px;
  line-height: 10px;
  padding: 1px 20px;
}

.testimonials .testimonials-content .testimonials-controls a:hover {
  color: #23527c;
}

.testimonials .slick-next::before,
.testimonials .slick-prev::before {
  color: var(--white);
  font-size: 29px;
  line-height: 1;
  opacity: 1;
}

.testimonials .slick-next,
.testimonials .slick-prev {
  bottom: -55px;
  font-family: FontAwesome;
  background: transparent;
  border-color: var(--white);
  font-size: 0;
  line-height: 0;
  top: unset;
  z-index: 9;
}

.testimonials .slick-prev {
  left: 0;
}

.testimonials .slick-next {
  left: 55px;
}

/* --testimonials ends-- */

/* Breadcrum starts */
.breadcrum {
  align-items: center;
  min-height: 447px;
  padding: 213px 44px 51px 32px;
  position: relative;
  width: 100%;
  // &::before {
  //   @include bgimgCont;
  //   background-image: url("../assets/images/reorg/shape.png");
  //   background-position: top left;
  //   opacity: 0.7;
  // }
  .breadcrum-content {
    position: relative;
  }
  h1 {
    color: var(--white);
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
    width: 100%;
    line-height: 94px;
  }
  p {
    color: var(--white);
    display: block;
    font-size: 18px;
    line-height: 160%;
  }
  &::before {
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    background: linear-gradient(81deg, rgba(0, 0, 0, 0.8) 38%, transparent);
    left: 0;
    top: 0;
    opacity: 1;
  }
  span {
    color: var(--white);
    float: right;
    line-height: 39px;
    text-transform: capitalize;
    em.seperator {
      font-style: inherit;
      padding: 0 5px;
    }
    a {
      color: var(--white);
      text-decoration: none;
    }
  }
  &.about-us {
    background-image: url("../assets/images/about-us.jpg");
    @include background-position;
  }

  &.contact-us {
    background-image: url("../assets/images/contact-us.jpg");
    @include background-position;
  }

  &.products {
    background-image: url("../assets/images/products.jpg");
    @include background-position;
  }

  &.solutions {
    background-image: url("../assets/images/solutions.jpg");
    @include background-position;
  }

  &.blog-details {
    background-size: cover !important;
  }

  &.faq {
    background-image: url("../assets/images/faq.jpg");
    @include background-position;
  }

  &.services {
    background-image: url("../assets/images/services.jpg");
    @include background-position;
  }

  &.terms-and-condition {
    background-image: url("../assets/images/terms-and-condition.jpg");
    @include background-position;
  }

  &.privacy-policy-img {
    background-image: url("../assets/images/privacy-policy.jpg");
    @include background-position;
  }

  &.case-study {
    background-image: url("../assets/images/privacy-policy.jpg");
    @include background-position;
  }

  &.stakeholders {
    background-image: url("../assets/images/stakeholders.jpeg");
    @include background-position;
  }
  &.healthcare {
    background-image: url("../assets/images/healthcare.jpg");
    @include background-position;
  }
  &.bigdata-analytics {
    background-image: url("../assets/images/big-data/big-data-analytics-solution.jpg");
    @include background-position;
  }
  &.tools {
    background-image: url("../assets/images/tools.jpg");
    @include background-position;
  }
  &.career {
    background-image: url("../assets/images/career.jpg");
    @include background-position;
  }
  &.why-choose-us {
    background-image: url("../assets/images/main-slider/why-choose-us.jpg");
    @include background-position;
  }
  &.corporate-meeting {
    background-image: url("../assets/images/main-slider/corporate-meeting.jpg");
    @include background-position;
  }
  &.workday-consulting-integrations-deployment {
    background-image: url("../assets/images/main-slider/workday-consulting-integrations-deployment.jpg");
    @include background-position;
  }
  &.client-side-deployment {
    background-image: url("../assets/images/main-slider/client-side-deployment.jpg");
    @include background-position;
  }
}

.contact-us-cta {
  background-image: url("../assets/images/main-slider/contact-us.png");
  @include background-position;
}

/* --Breadcrum ends-- */

/* ---------------------------
PRELOADER
----------------------------- */
.preeloader {
  background: #4c55c4 none repeat scroll 0 0;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
}
.preloader-spinner {
  animation: paddles 0.75s ease-out infinite;
  background-color: transparent;
  height: 40px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, 0, 0);
  width: 6px;
  &::before {
    animation: ballbounce 0.6s ease-out infinite;
    background-color: var(--white);
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 15px;
    width: 10px;
  }
}

@keyframes paddles {
  0% {
    box-shadow: -25px -10px 0 #fff, 25px 10px 0 var(--white);
  }

  50% {
    box-shadow: -25px 8px 0 #fff, 25px -10px 0 var(--white);
  }

  100% {
    box-shadow: -25px -10px 0 #fff, 25px 10px 0 var(--white);
  }
}

@keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }

  25% {
    transform: scale(1.2, 1);
  }

  50% {
    transform: translateX(15px) scale(1, 1.2);
  }

  75% {
    transform: scale(1.2, 1);
  }

  100% {
    transform: translateX(-20px);
  }
}

/* Preloader ends here */

/* features-sliders starts */
.features-sliders {
  padding: 90px 0;
  float: left;
  width: 100%;
  .list {
    float: left;
    margin-bottom: 20px;
    width: 100%;
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    .left-cont,
    .right-cont {
      float: left;
      padding: 0 10px;
      width: 50%;
    }
    .left-cont {
      color: #545454;
      min-height: 250px;
      padding: 0 25px;
      h3 {
        color: #242424;
        font-size: 25px;
        line-height: 161%;
        margin-bottom: 20px;
        margin-top: 0;
        span {
          color: var(--primary);
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          line-height: 161%;
          list-style-type: none;
          margin-bottom: 10px;
          padding-left: 32px;
          position: relative;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            color: #545454;
            content: "\f192";
            font-family: FontAwesome;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            left: 0;
            position: absolute;
            top: 1px;
            background: unset;
          }
        }
      }
    }
    .right-cont {
      img {
        border-radius: 5px;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        cursor: pointer;
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
  .slick-next::before,
  .slick-prev::before {
    color: var(--text) !important;
    font-size: 40px !important;
  }
}

/* features-sliders ends  */

/* Document IT-module-points starts here */
.gray-bg {
  background: #f6f6f6;
}

.blue-bg {
  background: rgba(230, 233, 255, 0.7333333333333333);
}

.white-bg {
  background: #fff !important;
}

.documentit-module-points {
  float: left;
  padding: 60px 0 90px;
  width: 100%;
}

.documentit-module-points {
  .col-lg-4:nth-child(3n + 1) {
    clear: both;
  }
  .heading {
    margin-bottom: 60px;
  }
  h3 {
    font-size: 22px;
    line-height: 150%;
    margin-bottom: 11px;
    padding-top: 80px;
    transition: all 0.3s;
  }
  .module-list {
    margin-top: 30px;
    img {
      border-radius: 10px;
      width: 100%;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 10px;
      padding-left: 25px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        color: #868890;
        content: "\f192";
        font-family: FontAwesome;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        left: 0;
        position: absolute;
        top: 1px;
      }
      &:hover::before {
        color: var(--text);
      }
    }
  }
}

/* documentit-module-points ends here */

/* --documentit Products starts -- */
.documentit-products {
  // background: #006db6;
  display: table;
  float: left;
  min-height: 100vh;
  overflow: hidden;
  padding: 55px 0 50px;
  position: relative;
  text-align: left;
  width: 100%;

  .product-details {
    display: table-cell;
    padding: 50px 0;
    position: relative;
    vertical-align: middle;
    z-index: 1;
    h1 {
      font-size: 35px;
      line-height: 150%;
      margin-bottom: 15px;
      margin-top: 0;
      text-align: left;
      text-transform: capitalize;
    }
    p,
    strong {
      font-size: 16px;
    }
    p {
      display: block;
      line-height: 180%;
      margin: 0 auto;
      margin-bottom: 20px;
      text-align: left;
      width: 100%;
      span {
        font-size: 20px;
        font-weight: normal;
        line-height: 178%;
      }
    }
    video {
      border-radius: 5px;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      width: 100%;
    }
    a {
      &:first-child {
        display: inline-block;
        margin-right: 10px;
      }
    }
    a.btn {
      border-color: var(--primary);
      color: var(--primary);
      &.btn-primary {
        display: inline-block;
        margin-right: 17px;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 84%;
    }
    .col-sm-6 {
      text-align: center;
      a.text-link {
        background: none;
        color: #222;
        float: none;
        font-size: 20px;
        font-weight: 600;
        font-weight: normal;
        &:hover {
          background: none;
        }
      }
    }
  }
}

/* --documentit Products ends -- */

/* for-more-information starts here */
.for-more-information {
  float: left;
  padding: 90px 0;
  text-align: center;
  width: 100%;
  h4 {
    color: var(--text);
    line-height: 177%;
    margin-bottom: 30px;
    text-transform: capitalize;
    span {
      color: var(--primary);
    }
  }
  h4 {
    font-size: 30px;
  }
  a {
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    margin-top: 20px;
    padding: 10px 20px;
    text-align: left;
    font-size: 16px;
  }
}

/* for-more-information ends here */

/* -- features-- */
.documentit-features {
  float: left;
  padding: 30px 0 90px;
  width: 100%;
  .col-sm-6.col-md-6.col-lg-4:nth-child(3n + 1) {
    clear: both;
  }
  .list {
    float: left;
    margin-top: 15px;
    padding: 10px 0;
    width: 100%;
    &:hover {
      h4 {
        color: #0083ff;
      }
    }
    h4 {
      font-size: 22px;
      line-height: 150%;
      margin-bottom: 11px;
      transition: all 0.3s;
    }
    p {
      line-height: 150%;
    }
    img {
      height: 50px;
      margin-bottom: 17px;
      width: 50px;
    }
  }
}

/* -- features-- */

/* -- features navigation starts-- */

/* .hbd-blog-library  start here */
.features-navigation {
  display: block;
  margin-bottom: 20px;
  padding: 20px 0 0;
  width: 100%;
  ul {
    display: block;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      list-style: none;
      text-align: center;
      a {
        border: 1px solid #9e9e9e;
        border-radius: 27px;
        color: #040404;
        margin-right: 6px;
        outline: none;
        padding: 9px 27px;
        position: relative;
        text-decoration: none !important;
      }
      &.active {
        a {
          background-color: #505ba8;
          border: 1px solid #505ba8;
          color: var(--white);
          &::before {
            color: #505ba8;
            content: "\f0dd";
            font-family: FontAwesome;
            font-size: 43px;
            font-style: normal;
            font-weight: normal;
            left: 42%;
            padding-right: 0.5em;
            position: absolute;
            text-decoration: inherit;
            top: 5px;
          }
        }
      }
    }
  }
}

/* -- features navigation ends-- */

/* --features-video page starts-- */
.features-video {
  background-color: #f6f6f6;
  background-image: url("/assets/images/pattern.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  padding: 90px 0;
  position: relative;
  width: 100%;
  &::before {
    background: rgba(232, 232, 232, 0.25);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  h3 {
    color: #2d3136;
    font-size: 35px;
    margin-bottom: 32px;
    text-align: center;
  }
  iframe {
    background: #ffff;
    border-radius: 4px;
    height: 400px;
    padding: 10px;
    width: 100%;
  }
}

/* --features-video page ends-- */

/* modules-and-statistics starts here */
.modules-and-statistics {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.modules-and-statistics .heading h2 {
  text-transform: capitalize;
}

.modules-and-statistics .list {
  float: left;
  padding: 0 10px;
  width: 100%;
}

.modules-and-statistics .list:hover,
.modules-and-statistics .list:active,
.modules-and-statistics .list:focus {
  outline: none;
}

.modules-and-statistics .list .details {
  float: left;
  width: 100%;
}

.modules-and-statistics .list .details img {
  max-width: 100%;
}

.modules-and-statistics .modules-and-statistics-slide {
  width: 100%;
}

.modules-and-statistics .list .details h4 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 0;
  text-transform: capitalize;
}

.modules-and-statistics .list .details img {
  border-radius: 5px;
  display: block;
  margin: 0 auto;
}

.modules-and-statistics .slick-next::before,
.modules-and-statistics .slick-prev::before {
  color: var(--text) !important;
  font-size: 40px !important;
}

/* modules-and-statistics ends here */

/* --how we help  starts-- */
.howwe-help {
  background: var(--white);
  float: left;
  padding: 90px 0;
  width: 100%;
  .content {
    float: left;
    width: 100%;
    h2 {
      color: #2d3136;
      font-size: 28px;
    }
    p {
      line-height: 201%;
      margin-top: 20px;
    }
    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 70%;
    }
  }
}
/* --how we help  ends-- */

/* error-page starts here */
.error-page {
  background: #2196f3;
  float: left;
  padding: 126px 0 60px;
  text-align: center;
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  h2,
  p {
    text-transform: capitalize;
  }
  h2 {
    color: var(--white);
    font-size: 65px;
    margin-bottom: 14px;
  }
  p {
    color: var(--white);
    font-size: 24px;
    margin-bottom: 26px;
  }
  a {
    background: #1890ff;
    border-radius: 31px;
    color: var(--white);
    display: inline-block;
    font-size: 23px;
    padding: 10px 20px;
    &:hover {
      background: #545daa;
    }
  }
}

/* error-page ends here */

/* request-call-back starts here */
.request-call-back {
  background: var(--white);
  float: left;
  padding: 90px 0;
  width: 100%;
  h3 {
    font-size: 35px;
    margin-bottom: 20px;
  }
  img {
    width: 33%;
  }
  p {
    line-height: 180%;
  }
  form {
    float: left;
    width: 100%;
    .form-fields {
      float: left;
      margin-bottom: 20px;
      padding: 0 10px;
      width: 50%;
      &:last-child {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 10px;
        width: 100%;
      }
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="url"] {
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        outline: none;
        padding: 15px 20px;
        width: 100%;
      }
      input[type="button"] {
        background: #505ba8;
        border: 1px solid #505ba8;
        border-radius: 27px;
        color: var(--white);
        outline: none;
        padding: 10px 50px;
      }
    }
    .form-fields:nth-child(3),
    .form-fields:last-child {
      width: 100%;
    }
    .form-fields:last-child {
      margin-top: 20px;
    }
  }
}

/* request-call-back ends here */

/* contact-us-page starts here */
.contact-us-page {
  // background: var(--white);
  padding: 60px 0;
  width: 100%;
  position: relative;
  .heading {
    float: left;
    margin-bottom: 47px;
    text-align: center;
    width: 100%;
    z-index: auto;
    h3 {
      font-size: 35px;
      margin-bottom: 20px;
      text-transform: capitalize;
      @media (max-width: 900px) {
        font-size: 23px;
      }
    }

    p {
      line-height: 180%;
      margin: 0 auto;
      max-width: 56%;
      @media (max-width: 900px) {
        max-width: 100%;
      }
    }
  }
  img {
    margin-bottom: 30px;
    width: 100%;
  }
  .form-fields {
    float: left;
    margin-bottom: 26px;
    padding: 0 10px;
    width: 50%;
    &:last-child {
      margin-bottom: 0;
    }
    label {
      font-weight: normal;
      font-weight: 500;
      margin-bottom: 10px;
      width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    input[type="url"] {
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      font-size: 15px;
      outline: none;
      padding: 14px 20px;
      width: 100%;
    }

    textarea {
      height: 80px;
      max-height: 80px;
      max-width: 100%;
      min-height: 80px;
      min-width: 100%;
    }
    input[type="button"] {
      background: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 6px;
      color: var(--white);
      outline: none;
      padding: 8px 32px;
    }

    input[type="button"]:hover {
      background: #545daa;
      border: 1px solid #545daa;
    }
  }

  .form-fields:nth-child(5),
  .form-fields:nth-child(6),
  .form-fields:last-child {
    width: 100%;
  }

  .form-fields:last-child {
    margin-top: 6px;
  }
}

/* request-call-back ends here */

/* locations starts here */
.locations {
  background: #eef7ff;
  float: left;
  padding: 60px 0;
  width: 100%;
}

.locations h2 {
  font-size: 35px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.locations img {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 65%;
}

.locations .office-details {
  border-radius: 4px;
  float: left;
  margin-bottom: 30px;
  min-height: 267px;
  padding: 20px;
  width: 100%;
}

.locations .office-details img {
  margin-bottom: 12px;
  width: 28px;
}

.locations .office-details {
  background: var(--white);
  -webkit-box-shadow: 0 2px 23px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 23px rgba(0, 0, 0, 0.08);
}

.locations .office-details h3 {
  font-size: 20px;
  line-height: 180%;
  margin-bottom: 9px;
  transition: all 0.3s;
}

.locations .office-details p {
  line-height: 160%;
  margin-bottom: 15px;
}

.locations .office-details:hover h3 {
  color: #0083ff;
}

.locations .office-details ul {
  list-style: none;
  padding: 0;
}

.locations .office-details ul li {
  list-style-type: none;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.locations .office-details ul li:last-child {
  margin-bottom: 0;
}

.locations .office-details ul li::before {
  /* --adjust as necessary-- */
  color: #6c77d1;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  left: 1px;
  padding-right: 0.5em;
  position: absolute;
  text-decoration: inherit;
  top: 0;
}

.locations .office-details ul li.email::before {
  content: "\f003";
}

.locations .office-details ul li.phone::before {
  content: "\f095";
}

.locations .office-details ul li a {
  color: #0083ff;
}

.locations .office-details ul li span {
  font-weight: 500;
}

/* locations ends here */

/* Solutions page starts here */

/* --Solution slider strats here-- */
.documentit-solution {
  background: var(--white);
  padding: 60px 0;
  width: 100%;
  position: relative;
  &::before {
    @include bgimgContBottom;
    background-image: url("../assets/images/inner-pages-1.png");
    background-position: bottom right;

    opacity: 0.7;
  }
  .solutions-list {
    width: 100%;
    .list {
      margin-bottom: 10px;
      text-align: left;
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        width: 100%;
        padding: 0;
      }
      &:nth-child(3n + 1) {
        clear: both;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 22px;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      h4 {
        font-size: 19px;
        line-height: 150%;
        text-transform: capitalize;
      }
      p {
        line-height: 168%;
        margin-top: 14px;
        color: var(--text);
      }
      h4 {
        color: var(--text);
      }
      .details {
        width: 100%;
        box-shadow: 0 0 20px 7px #f1f1f1;
        border-radius: 10px;
        background: var(--white);
        padding-bottom: 10px;
        @media (max-width: 500px) {
          min-height: auto;
        }
        .spec {
          padding: 20px;
        }
        .hovercontent {
          background: #505ba8;
          border-radius: 4px;
          display: none;
          height: 100%;
          left: 0;
          padding: 20px;
          position: absolute;
          text-align: left;
          top: 0;
          transition: all 0.3s;
          width: 100%;
          z-index: 9;
          .cont {
            margin: 0 auto;
            width: 92%;
            a::after {
              color: var(--white);
              content: "\f105";
              font-family: FontAwesome;
              font-size: 20px;
              font-style: normal;
              font-weight: normal;
              position: absolute;
              right: 10px;
              top: 4px;
            }
            p {
              color: var(--white);
              font-size: 16.5px;
              line-height: 160%;
            }
            a,
            button {
              background: none;
              border: 1px solid var(--white);
              border-radius: 6px;
              bottom: 20px;
              color: var(--white);
              display: inline-block;
              outline: none;
              padding: 6px 34px 6px 20px;
              position: absolute;
              a:hover,
              button:hover {
                background: #9ce;
                border-color: #9ce;
                outline: none;
              }
            }
          }
        }
        &:hover > .hovercontent {
          display: block;
        }
      }
    }
  }
}

.documentit-solution.products-space .solutions-list .list h4 {
  min-height: auto;
}

/* Solutions page ends here */

/* call-back-poup starts here */
.call-back-popup {
  background: rgba(0, 0, 0, 0.41);
  float: left;
  height: 100vh;
  left: 0;
  padding: 90px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.call-back-popup .popup {
  background: var(--white);
  border: 0;
  border-radius: 5px;
  display: block;
  display: table;
  margin: 0 auto;
  padding: 30px 20px;
  position: relative;
  width: 52%;
}

.call-back-popup .popup .close-button {
  cursor: pointer;
  position: absolute;
  right: -9px;
  top: -18px;
}

.call-back-popup .popup .close-button button {
  background: #ff4000;
  border: 0;
  border-radius: 50%;
  color: var(--white);
  font-size: 20px;
  height: 30px;
  line-height: 2px;
  outline: none;
  padding: 0;
  text-align: center;
  width: 30px;
}

.call-back-popup .popup h2 {
  font-size: 21px;
  margin-bottom: 23px;
  text-align: center;
  text-transform: uppercase;
}

.call-back-popup .popup .cont {
  float: left;
  padding: 0 5px;
  width: 50%;
}

.call-back-popup .popup .cont img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.call-back-popup .popup .cont form {
  float: left;
  width: 100%;
}

.call-back-popup .popup .cont form .form-fields {
  float: left;
  margin-bottom: 15px;
  width: 100%;
}

.call-back-popup .popup .cont form .form-fields:last-child {
  margin-bottom: 0;
}

.call-back-popup .popup .cont form .form-fields input[type="text"],
.call-back-popup .popup .cont form .form-fields input[type="email"],
.call-back-popup .popup .cont form .form-fields input[type="tel"],
.call-back-popup .popup .cont form .form-fields textarea,
.call-back-popup .popup .cont form .form-fields input[type="url"] {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

.call-back-popup .popup .cont form .form-fields textarea {
  height: 80px;
  max-height: 80px;
  max-width: 100%;
  min-height: 80px;
  min-width: 100%;
}

.call-back-popup .popup .cont form .form-fields:last-child {
  margin-top: 6px;
}

.call-back-popup .popup .cont form .form-fields input[type="button"] {
  background: #1890ff;
  border: 1px solid #1890ff;
  border-radius: 27px;
  color: var(--white);
  outline: none;
  padding: 6px 23px;
  width: 100%;
}

.call-back-popup .popup .cont form .form-fields input[type="button"]:hover {
  background: #545daa;
  border: 1px solid #545daa;
}

/* call-back-poup ends here */

/* --Footer Starts-- */
.footer {
  // background-color: #222;
  background-color: var(--footer);
  float: left;
  padding: 70px 0;
  width: 100%;
  .links {
    margin-bottom: 20px;
    min-height: 133px;
    h4 {
      color: var(--white);
      cursor: auto;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 10px;
      padding-left: 0;
      padding-right: 0;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        a {
          color: #8d8f94;
          display: inline-block;
          font-size: 15px;
          font-weight: 400;
          line-height: 33px;
          position: relative;
          text-decoration: none;
          transition: all 0.3s linear;
          transition: color 0.15s ease 0s;
          &::before {
            background-color: #8bd;
            bottom: 4px;
            content: "";
            height: 1px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            transition: all 0.3s ease 0s;
            width: 0%;
          }
          &:hover {
            color: #9ce;
            &::before {
              width: 100%;
            }
          }
          i {
            font-family: FontAwesome;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/* --footer-cta Starts-- */
.footer-cta {
  border: 0;
  float: left;
  width: 100%;
  .cta-details {
    margin-bottom: 20px;
    padding-left: 82px;
    position: relative;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child::before,
    &:last-child::before,
    &:nth-child(2)::before {
      font-family: FontAwesome;
      font-size: 41px;
      font-style: normal;
      font-weight: normal;
      left: 14px;
      /* --adjust as necessary-- */
      padding-right: 0.5em;
      position: absolute;
      text-decoration: inherit;
      top: 11px;
    }
    &:first-child {
      &::before {
        color: #63bf7d;
        content: "\f095";
      }
    }
    &:nth-child(2) {
      &::before {
        color: #63bf7d;
        content: "\f1ac";
      }
    }
    &:last-child {
      &::before {
        color: var(--primary);
        content: "\f003";
      }
    }

    i {
      color: var(--primary);
      font-size: 31px;
      left: 14px;
      position: absolute;
      top: 0;
    }
    h4 {
      font-size: 20px;
      margin-bottom: 13px;
      a {
        text-decoration: none;
        font-size: 20px;
      }
    }
    p {
      color: #8d8f94;
      display: inline-block;
      font-size: 17px;
      font-weight: 400;
    }
  }
}
/* --Bottom Footer ends-- */
.bottom-footer {
  float: left;
  margin-top: 30px;
  width: 100%;
  .copyright-text {
    border-top: 1px solid #232647;
    padding-top: 28px;
    width: 100%;
    p {
      color: var(--text);
      font-size: 15px;
      margin-bottom: 0;
      text-align: right;
      @media (max-width: 767px) {
        text-align: center;
      }
      a {
        color: #8d8f94;
        text-decoration: none;
      }
    }
  }
  .social-icon {
    color: var(--white);
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
    @media (max-width: 767px) {
      text-align: center;
    }
    a {
      margin-left: 5px;
      text-decoration: none;
      color: var(--text);
      i {
        font-size: 21px;
        padding: 0 7px;
      }
    }
    a {
      color: #7b858f;
      &:hover {
        color: #535455;
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        padding: 0 10px;
      }
    }
  }
}

/* --Footer ends-- */

/* search starts here */
.search-button {
  cursor: pointer;
}

.right-menu ul li div.searchbar {
  background: var(--white);
  -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
  display: none;
  margin-top: 10px;
  min-width: 20rem;
  opacity: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  transition: all 0.5s ease;
  visibility: hidden;
}

.right-menu ul li div.searchbar::before {
  color: var(--white);
  content: "\f0de";
  font-family: FontAwesome;
  font-size: 35px;
  font-style: normal;
  font-weight: normal;
  padding-right: 0.5em;
  position: absolute;
  right: -3%;
  text-decoration: inherit;
  top: -19px;
}

.right-menu ul li:hover > div.searchbar {
  display: block;
  opacity: 1;
  visibility: visible;
}

.right-menu ul li div.searchbar input[type="text"] {
  border: 1px solid var(--white);
  float: left;
  outline: none;
  padding: 5px 10px;
}

.right-menu ul li div.searchbar button {
  background: #1890ff;
  border: 0;
  border-radius: 35px;
  color: var(--white);
  display: inline-block;
  outline: none;
  padding: 5px 15px;
  text-decoration: none;
  width: auto;
}

/* search ends here */

/* privacy-policy starts */
.privacy-policy {
  float: left;
  padding: 90px 0;
  width: 100%;
  h3 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 25px;
    line-height: 170%;
    margin-bottom: 13px;
  }
  p {
    line-height: 180%;
    margin-bottom: 19px;
  }
}

/* privacy-policy ends */

/* Animal warehouse starts */
.clinical-trial {
  float: left;
  padding: 90px 0;
  width: 100%;
  .title {
    margin-bottom: 20px;
    h2 {
      color: #333b7a;
      font-size: 34px;
    }
  }
  .advantages-list {
    float: left;
    width: 100%;
    .list {
      float: left;
      margin-top: 40px;
      padding: 0 20px;
      width: 33.333%;
      &:nth-child(3n + 1) {
        clear: both;
      }
      .details {
        line-height: 150%;
        padding-left: 25px;
        position: relative;
        &::before {
          background: #1890ff;
          border-radius: 50%;
          color: var(--white);
          content: "\f00c";
          font-family: FontAwesome;

          font-style: normal;
          font-weight: normal;
          height: 30px;
          left: -15px;
          line-height: 172%;
          padding-right: 0;
          position: absolute;
          text-align: center;
          text-decoration: inherit;
          top: 0;
          width: 30px;
        }
        h3 {
          font-size: 20px;
          line-height: 150%;
        }
      }
    }
  }
  h4 {
    font-size: 21px;
    margin-bottom: 20px;
    text-align: left;
  }
}

/* Animal warehouse ends here */

/* we verify  */
.we-verify-students {
  float: left;
  padding: 0 0 90px;
  width: 100%;
}

.we-verify-students .image,
.we-verify-students .detailed-view {
  float: left;
  width: 100%;
}

.we-verify-students .image img {
  display: block;
  margin: 0 auto;
  max-width: 90%;
}

.we-verify-students h4 {
  color: #0f111a;
  font-size: 30px;
  line-height: 150%;
  margin-top: 46px;
  position: relative;
}

.we-verify-students .list {
  float: left;
  margin-top: 52px;
  padding: 0 20px;
  width: 50%;
}

.we-verify-students .list .details {
  line-height: 150%;
  padding-left: 25px;
  position: relative;
}

.we-verify-students .list .details h5 {
  font-size: 21px;
  font-weight: 500;
}

.we-verify-students .list .details::before {
  background: #1890ff;
  border-radius: 50%;
  color: var(--white);
  content: "\f00c";
  font-family: FontAwesome;

  font-style: normal;
  font-weight: normal;
  height: 30px;
  left: -15px;
  line-height: 172%;
  padding-right: 0;
  position: absolute;
  text-align: center;
  text-decoration: inherit;
  top: 0;
  width: 30px;
}

/* we verify ends here */

/* machine learning solutions starts */
.inner-page-solutions {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.inner-page-solutions .solutions-list {
  float: left;
  margin-top: 20px;
  width: 100%;
}

.inner-page-solutions .solutions-list .list {
  float: left;
  margin-bottom: 39px;
  padding: 0 20px;
  text-align: center;
  width: 33.333%;
}

.inner-page-solutions .solutions-list .list:nth-child(3n + 1) {
  clear: both;
}

.inner-page-solutions .solutions-list .list .details {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;

  /* display:none; */
}

.inner-page-solutions .solutions-list .list .details .hovercontent {
  background: rgba(103, 58, 183, 0.83);
  border-radius: 4px;
  display: none;
  height: 100%;
  left: 0;
  padding: 20px;
  position: absolute;
  text-align: left;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 9;
}

.inner-page-solutions .solutions-list .list .details:hover > .hovercontent {
  display: block;
}

.inner-page-solutions .solutions-list .list .details .hovercontent .cont {
  margin: 0 auto;
  text-align: center;
  width: 92%;
}

.inner-page-solutions .solutions-list .list .details .hovercontent .cont p {
  color: var(--white);
  font-size: 16px;
  line-height: 160%;
}

.inner-page-solutions .solutions-list .list .details .hovercontent .cont a {
  color: var(--white);
  display: inline-block;
  padding: 100px 0;
  text-align: center;
}

.inner-page-solutions .solutions-list .list:nth-child(1) .details {
  background: #f0506b;
  display: block;
}

.inner-page-solutions .solutions-list .list:nth-child(2) .details {
  background: #9b8ffb;
  display: block;
}

.inner-page-solutions .solutions-list .list:nth-child(3) .details {
  background: #eca2c5;
  display: block;
}

.inner-page-solutions .solutions-list .list:nth-child(4) .details {
  background: #c9dfff;
  display: block;
}

.inner-page-solutions .solutions-list .list:nth-child(5) .details {
  background: #e2e2e3;
}

.inner-page-solutions .solutions-list .list:nth-child(6) .details {
  background: #ff9800;
}

.inner-page-solutions .solutions-list .list:nth-child(7) .details {
  background: #9c27b0;
}

.inner-page-solutions .solutions-list .list:nth-child(8) .details {
  background: #3f51b5;
}

.inner-page-solutions .solutions-list .list:nth-child(9) .details {
  background: #00bcd4;
}

.inner-page-solutions .solutions-list .list:nth-child(10) .details {
  background: #009688;
}

.inner-page-solutions .solutions-list .list:nth-child(11) .details {
  background: #8bc34a;
}

.inner-page-solutions .solutions-list .list a {
  display: block;
}

.inner-page-solutions .solutions-list .list img {
  display: block;
  margin: 0 auto;
  width: 80%;
}

.inner-page-solutions .solutions-list .list h4 {
  background: #f7f7f7;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #373d46;
  font-size: 20px;
  line-height: 150%;
  padding: 20px;
}

/* Solutions page ends here */

/* Solutions detailed service */

.solution-services {
  float: left;
  padding: 60px 0 90px;
  width: 100%;
}

.solution-services .col-lg-4:nth-child(3n + 1) {
  clear: both;
}

.solution-services .heading {
  margin-bottom: 60px;
}

.solution-services h2 {
  text-transform: capitalize;
}

.solution-services h3 {
  color: #2c4b8d;
  font-size: 22px;
  line-height: 150%;
  margin-bottom: 11px;
  transition: all 0.3s;
}

.solution-services .module-list {
  margin-top: 30px;
}

.solution-services ul {
  list-style: none;
  padding: 0;
}

.solution-services ul li {
  list-style-type: none;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.solution-services ul li:last-child {
  margin-bottom: 0;
}

.solution-services ul li::before {
  color: #868890;
  content: "\f02e";
  font-family: FontAwesome;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  left: 0;
  position: absolute;
  top: 1px;
}

.solution-services ul li:hover::before {
  color: var(--text);
}

/* machie learning solutions ends */

/* blog grids starts here */
.blog-grids {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.blog-grids .blog-lists {
  display: block;
  width: 100%;
}

.blog-grids .blog-lists .list {
  float: left;
  margin-bottom: 20px;
  padding: 0 10px;
  position: relative;
  width: 33.333%;
}

.blog-grids .blog-lists .list .details {
  border-radius: 6px;
  height: 217px;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.blog-grids .blog-lists .list .details::before {
  background: rgba(0, 0, 0, 0.48);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.background-image {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.blog-grids .blog-lists .list img {
  max-width: 100%;
}

.blog-grids .blog-lists .list .blog-content {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 99;
}

/* title starts here */
.blog-grids .blog-lists .list .blog-content .title {
  width: 100%;
}

.blog-grids .blog-lists .list .blog-content .title span {
  color: var(--white);
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  width: 100%;
}

.blog-grids .blog-lists .list .blog-content .title h2 {
  color: var(--white);
  font-size: 21px;
  line-height: 167%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* title ends here */

.blog-grids .blog-lists .list .blog-content .middle-content {
  -webkit-box-orient: vertical;
  color: var(--white);
  display: none;
  -webkit-line-clamp: 3;
  margin-top: 4px;
  overflow: hidden;
  transition: all 0.3s;
}

.blog-grids .blog-lists .list:hover .middle-content {
  display: -webkit-box;
}

.blog-grids .blog-lists .list .blog-content .middle-content p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.blog-grids .blog-lists .list .blog-content .middle-content p span {
  color: #fff !important;
}

/* middle content */

.blog-grids .blog-lists .list .blog-content .bottom-content {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .right,
.blog-grids .blog-lists .list .blog-content .bottom-content .left {
  float: left;
  width: 50%;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .right {
  text-align: right;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .left {
  text-align: left;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .left a,
.blog-grids .blog-lists .list .blog-content .bottom-content .left button {
  color: #ff5722;
  font-weight: 600;
  font-weight: normal;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .left button {
  background: none;
  border: 0;
  outline: none;
}

.blog-grids .blog-lists .list .blog-content .bottom-content .right .comments {
  text-align: right;
  width: 100%;
}

.blog-grids
  .blog-lists
  .list
  .blog-content
  .bottom-content
  .right
  .comments
  span {
  color: var(--white);
}

.blog-grids
  .blog-lists
  .list
  .blog-content
  .bottom-content
  .right
  .comments
  span
  i {
  color: #1890ff;
  font-family: FontAwesome;
  font-size: 20px;
  margin-right: 5px;
}

.blog-grids .load-more-button {
  float: left;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.blog-grids .load-more-button button {
  background: #505ba8;
  border: 1px solid #505ba8;
  border-radius: 27px;
  color: var(--white);
  outline: none;
  padding: 7px 20px;
}

.blog-grids .load-more-button button:hover {
  background: #ff5722;
  border-color: #ff5722;
}

/* bottom content */

/* blogs grid ends here */

/* blog details sections starts */
.blog-details-section {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.blog-details-section .details {
  float: left;
  width: 100%;
}

.blog-details-section .details h1,
.blog-details-section .details h2,
.blog-details-section .details h3,
.blog-details-section .details h4,
.blog-details-section .details h5,
.blog-details-section .details ul,
.blog-details-section .details ul li {
  line-height: 150%;
  margin-bottom: 10px;
}

.blog-details-section .details p {
  color: #5d5755;
  line-height: 188%;
  margin-bottom: 30px;
}

.blog-details-section .details img {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 100%;
}

/* blog details section ends */

/* login starts here */
.login {
  background-color: var(--white);
  background-image: url("../assets/images/login-shape.svg");
  background-position: center center;
  background-repeat: no-repeat;
  float: left;
  height: 100vh;
  width: 100%;
}

.login .content {
  display: table;
  height: 570px;
  min-height: 570px;
  position: relative;
  width: 100%;
}

.login .content .center-content {
  display: table-cell;
  outline: none;
  position: relative;
  vertical-align: middle;
  z-index: 3;
}

.login .content .center-content .login-sections {
  display: flex;
  margin: 0 auto;
  min-height: 200px;
  width: 70%;
}

.login .content .center-content .login-sections .left {
  background: #673ab7;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.login .content .center-content .login-sections .right {
  background: var(--white);
  border: 1px solid #f5f5f5;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.login .content .center-content .login-sections .left,
.login .content .center-content .login-sections .right {
  padding: 0 10px;
  width: 50%;
}

.login .content .center-content .login-sections .left .details,
.login .content .center-content .login-sections .right .details {
  float: left;
  padding: 20px;
  width: 100%;
}

.login .content .center-content .login-sections .left .details img {
  margin-top: 5%;
  max-width: 100%;
}

.login .content .center-content .login-sections .heading {
  text-align: center;
}

.login .content .center-content .login-sections .right .details .heading img {
  height: 40px;
  margin-bottom: 14px;
}

.login .content .center-content .login-sections .right .details .heading h1 {
  font-size: 25px;
  margin-bottom: 30px;
}

.login .content .center-content .login-sections .right .details form {
  width: 100%;
}

.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  .form-fields {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}

.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  .form-fields:last-child {
  margin-bottom: 0;
}

.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  input[type="text"],
.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  input[type="password"] {
  background: var(--white);
  border: 1px solid #ebebeb;
  border-radius: 7px;
  outline: none;
  padding: 12px 16px;
  width: 100%;
}

.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  button[type="submit"],
.login .content .center-content .login-sections .right .details form button,
.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  input[type="submit"] {
  background: #4caf50;
  border: none;
  border-radius: 27px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  margin-top: 15px;
  outline: none;
  padding: 7px 16px;
  width: 100%;
}

.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  button[type="submit"]:hover,
.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  button:hover,
.login
  .content
  .center-content
  .login-sections
  .right
  .details
  form
  input[type="submit"]:hover {
  background: var(--text);
}

/* login ends here */

/* --documentit  Accordian starts-- */

.documentit-accordian {
  background: #f6f9f9;
  float: left;
  padding: 90px 0;
  width: 100%;
  &.freequent-question-page {
    background: #fbfbfb;
    margin-bottom: 0;
    padding-top: 50px;
  }
  h2 {
    color: #2d3136;
    font-size: 35px;
    margin-bottom: 42px;
    position: relative;
    text-align: center;
  }
  .accordion-container,
  .content,
  .pagination-section {
    width: 100%;
  }
  .pagination-section {
    margin-top: 39px;
    text-align: center;
  }
  .accordion-container {
    .accordion {
      border: 0;
      .accordion__item {
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
        margin-bottom: 13px;
      }
      .accordion__item + .accordion__item {
        border-top: 0;
      }
      .accordion__button {
        background-color: var(--white);
        border-radius: 5px;
        color: #2d3136;
        font-size: 21px;
        font-weight: 600;
        font-weight: normal;
        outline: none;
        position: relative;
        text-align: left;
        &::before {
          position: relative;
          top: -4px;
        }
      }
      .accordion__panel {
        background: var(--white);
      }
    }
  }
}

.ac {
  border-bottom: 1px solid #ddd;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 2px;
  &:last-child {
    border-bottom: 0;
  }
  > .ac-q {
    color: #111;
    cursor: pointer;
    display: block;
    font-size: 21px;
    margin: 0;
    outline: none;
    padding: 20px 30px 20px 10px;
    position: relative;
    text-decoration: none;
    &::after {
      content: "\f105";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      position: absolute;
      right: 10px;
      text-align: center;
      text-decoration: inherit;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      width: 15px;
    }
  }
  > .ac-a {
    overflow: hidden;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
  }
  > .ac-a p {
    margin: 0;
    padding: 10px;
  }

  &.js-enabled > .ac-a {
    visibility: hidden;
  }
  &.active > .ac-a {
    visibility: visible;
  }
  &.active > .ac-q::after {
    content: "\f107";
  }
}

/* --documentit  Accordian ends-- */

.faq-search {
  margin-bottom: 20px;
  width: 100%;
  form {
    display: block;
    margin: 0 auto;
    width: 60%;
    .form-fields {
      position: relative;
      width: 100%;
      input[type="text"] {
        background: var(--white);
        border: 1px solid #ebebeb;
        border-radius: 27px;
        outline: none;
        padding: 9px 43px 9px 16px;
        width: 100%;
      }
      button {
        background: 0;
        border: 0;
        outline: none;
        position: absolute;
        right: 15px;
        top: 9px;
      }
    }
  }
}

/* --Responsive starts here-- */

/* it security needs starts */
.it-security-needs {
  float: left;
  padding: 90px 0;
  width: 100%;
  h2 {
    color: var(--text);
    text-align: center;
  }
  .needs {
    float: left;
    margin-top: 53px;
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      li {
        float: left;
        font-size: 17px;
        font-weight: 600;
        font-weight: normal;
        margin-bottom: 35px;
        padding: 0 10px;
        text-align: center;
        width: 20%;
        .details {
          background: var(--white);
          border-radius: 8px;
          box-shadow: rgba(118, 118, 118, 0.2) 0 10px 30px 0;
          box-sizing: border-box;
          cursor: pointer;
          min-height: 107px;
          padding: 30px;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
          width: 100%;
        }
      }
    }
  }
}

/* it security needs ends */

/* iot-dev-services starts */
.iot-dev-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.iot-dev-services h2 {
  color: var(--text);
  margin-bottom: 55px;
  text-align: center;
}

.it-security-needs .needs {
  float: left;
  margin-top: 53px;
  width: 100%;
}

.iot-dev-services .needs ul {
  padding: 0;
}

.iot-dev-services .needs ul li {
  color: #3c3c3c;
  display: table;
  float: left;
  font-size: 20px;
  height: auto;
  margin-bottom: 33px;
  min-height: 126px;
  padding: 0 10px;
  position: relative;
  text-align: center;
  width: 25%;
}

.iot-dev-services .needs ul li .details {
  background: var(--white);
  border-radius: 8px;
  box-shadow: rgba(118, 118, 118, 0.2) 0 3px 13px 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  height: 126px;
  padding: 20px;
  position: relative;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  vertical-align: middle;
  width: 100%;
}

.iot-dev-services .needs ul li .details::before {
  background: #32c820;
  border-radius: 50%;
  color: var(--white);
  content: counter(my-sec-counter) "";
  counter-increment: my-sec-counter;
  font-size: 16px;
  height: 34px;
  left: -11px;
  line-height: 34px;
  position: absolute;
  top: -12px;
  width: 34px;
}

/* iot-dev-services needs ends */

/* How-its-helps-business starts here */
.how-its-helps-business {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.how-its-helps-business h2 {
  color: var(--white);
  margin-bottom: 30px;
  text-align: center;
}

.dark-bg {
  background: #00164d;
}

.how-its-helps-business .details {
  color: var(--white);
  float: left;
  margin-top: 28px;
  width: 100%;
}

.how-its-helps-business .details h3,
.how-its-helps-business .details p {
  color: var(--white);
}

.how-its-helps-business .details h3 {
  color: #fab455;
  margin-bottom: 15px;
}

.how-its-helps-business .details p {
  font-size: 19px;
}

/* How-its-helps-business ends here */

/* it-security-features starts */
.it-security-features {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.it-security-features h2 {
  margin-bottom: 76px;
  text-align: center;
}

.it-security-features .content {
  width: 100%;
}

.it-security-features .content img {
  background: #e1e1e1;
  border-radius: 7px;
  box-shadow: 0 2px 30px rgba(130, 130, 130, 0.18);
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.it-security-features .content ul li {
  float: left;
  font-size: 19px;
  line-height: 170%;
  list-style-type: none;
  margin-bottom: 19px;
  padding-left: 46px;
  position: relative;
}

.it-security-features .content ul li:last-child {
  margin-bottom: 0;
}

.it-security-features .content ul li::before {
  color: #8bc34a;
  content: "\f058";
  font-family: FontAwesome;
  font-size: 27px;
  font-style: normal;
  font-weight: normal;
  left: 0;
  position: absolute;
  top: 1px;
}

.it-security-features .content ul {
  float: left;
  padding: 0;
}

.it-security-features .contentul li {
  line-height: 184%;
  margin-bottom: 20px;
}

.it-security-features .content p {
  line-height: 184%;
  margin-bottom: 14px;
}

/* it-security-features ends */

/* blockchain platform starts */
.blockchain-platform {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.blockchain-platform h2 {
  color: var(--white);
  margin-bottom: 45px;
  text-align: center;
}

.blockchain-platform h3 {
  color: #fab455;
  margin-bottom: 15px;
}

.blockchain-platform p {
  color: var(--white);
  line-height: 150%;
}

.blockchain-platform .details {
  margin-top: 30px;
  padding-left: 50px;
  position: relative;
}

.blockchain-platform .details img {
  background: var(--white);
  border-radius: 5px;
  left: -12px;
  padding: 4px;
  position: absolute;
  top: 0;
  width: 50px;
}

/* blockchain platform ends */

/* blockchain services starts */
.blockchain-services {
  float: left;
  padding: 50px 0 90px;
  width: 100%;
}

.blockchain-services h2 {
  margin-bottom: 45px;
  text-align: center;
}

.blockchain-services h3 {
  line-height: 164%;
  margin-bottom: 15px;
}

.blockchain-services p {
  line-height: 164%;
}

.blockchain-services .details {
  margin-top: 30px;
  position: relative;
  text-align: center;
}

.blockchain-services .col-lg-4:nth-child(3n + 1) {
  clear: both;
}

.blockchain-services .details img {
  display: block;
  height: 64px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 4px;
  position: relative;
}

/* blockchain services ends */

/* machinelearning services starts */
.machinelearning-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.machinelearning-services h2 {
  margin-bottom: 20px;
  text-align: center;
}

.machinelearning-services p.title {
  font-size: 21px;
  margin: 0 auto;
  padding-bottom: 38px;
  text-align: center;
  width: 60%;
}

.machinelearning-services h3 {
  line-height: 164%;
  margin-bottom: 15px;
}

.machinelearning-services p {
  line-height: 164%;
}

.machinelearning-services .details {
  margin-top: 30px;
  position: relative;
  text-align: center;
}

.machinelearning-services .col-lg-3:nth-child(4n + 1) {
  clear: both;
}

.machinelearning-services .details img {
  display: block;
  height: 64px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 4px;
  position: relative;
}

/* blockchain services ends */

/* blockchain services starts */
.tech-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.tech-services h2 {
  margin-bottom: 45px;
  text-align: center;
}

.tech-services h3 {
  border-bottom: 1px dashed #dadada;
  font-size: 21px;
  line-height: 164%;
  margin-bottom: 23px;
  padding-bottom: 15px;
}

.tech-services p {
  line-height: 164%;
}

.tech-services .details {
  background: var(--white);
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 327px;
  padding: 20px;
  position: relative;
  text-align: left;
}

.tech-services .details ul {
  margin: 0;
  padding: 0;
}

.tech-services .details ul li {
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.tech-services .details ul li::before {
  /* --adjust as necessary-- */
  color: var(--text);
  content: "\f123";
  font-family: FontAwesome;

  font-style: normal;
  font-weight: normal;
  left: 0;
  padding-right: 0.5em;
  position: absolute;
  text-decoration: inherit;
  top: 0;
}

.tech-services .col-lg-4:nth-child(3n + 1) {
  clear: both;
}

.tech-services .details img {
  display: block;
  height: 64px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 4px;
  position: relative;
}

/* tech-services ends */

/* blockchain services starts */
.app-meintanace {
  float: left;
  padding: 50px 0 90px;
  width: 100%;
}

.app-meintanace h2 {
  margin-bottom: 45px;
  text-align: center;
}

.app-meintanace h3 {
  border-bottom: 1px dashed #dadada;
  font-size: 21px;
  line-height: 164%;
  margin-bottom: 23px;
  padding-bottom: 15px;
}

.app-meintanace p {
  line-height: 164%;
  margin-bottom: 20px;
}

/* tech-services ends */

/* our-customers starts here */
.our-customers {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.our-customers h2 {
  margin-bottom: 64px;
  text-align: center;
}

.our-customers .customer-list {
  float: left;
  width: 100%;
}

.our-customers .customer-list {
  text-align: center;
  width: 100%;
}

.our-customers .customer-list ul {
  display: inline-block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: auto;
}

.our-customers .customer-list ul li {
  float: left;
  margin-bottom: 26px;
  padding: 0 22px;
  width: 46%;
}

.our-customers .customer-list ul li img {
  background: var(--white);
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  padding: 13px 15px;
  width: 189px;
}

/* our-customers ends here */

/* workday-consulting starts */
.workday-consulting {
  float: left;
  padding: 50px 0 90px;
  width: 100%;
}

.workday-consulting h2 {
  color: #101232;
  font-size: 30px;
  margin-bottom: 22px;
}

.workday-consulting h5::before {
  background: #ed2238;
  bottom: -18px;
  content: "";
  height: 3px;
  left: 48%;
  position: absolute;
  width: 38px;
}

.workday-consulting h2,
.workday-consulting h5 {
  line-height: 150%;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.workday-consulting h5 {
  color: #f71735;
  font-size: 22px;
  margin-bottom: 45px;
}

.workday-consulting .cont {
  margin-top: 22px;
  width: 100%;
}

.workday-consulting p {
  font-size: 17px;
  line-height: 180%;
}

.workday-consulting .details,
.workday-consulting .list-details {
  margin-top: 22px;
  width: 100%;
}

.workday-consulting .details h3 {
  color: var(--text);
  font-size: 21px;
  margin-bottom: 20px;
}

.workday-consulting .details ul {
  list-style: decimal-leading-zero;
}

.workday-consulting .details ul li {
  line-height: 150%;
  margin-top: 15px;
}

.workday-consulting .list-details .list {
  float: left;
  margin-bottom: 23px;
  padding: 0 10px;
  width: 14%;
}

.workday-consulting .list-details .list:hover .desc {
  background: #fbfbfb;
}

.workday-consulting .list-details .list .desc {
  background: var(--white);
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 17px 15px;
  position: relative;
  text-align: center;
}

.workday-consulting .list-details .list .desc i {
  color: #d8d8d8;
  font-size: 17px;
  margin-bottom: 10px;
  position: absolute;
  right: 13px;
  top: 10px;

  /* display: none; */
}

.workday-consulting .list-details .list .desc h4 {
  color: #8bc34a;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 11px;
}

/* workday-consulting ends here */

/* invest-workday starts here */
.invest-workday {
  background: #ffcf58;
  float: left;
  padding: 90px 0;
  width: 100%;
}

.invest-workday .details {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

.invest-workday .details h4 {
  font-size: 25px;
  line-height: 150%;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.invest-workday .details p {
  line-height: 187%;
}

/* invest-workday ends here */

/* how-we-hear starts */
.how-we-hear {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.how-we-hear.details {
  float: left;
  width: 100%;
}

.how-we-hear .details thead tr th {
  font-size: 25px;
  line-height: 150%;
  margin-bottom: 20px;
  text-transform: capitalize;
  width: 50%;
}

.how-we-hear .details tbody tr td {
  font-size: 17px;
  line-height: 150%;
}

.how-we-hear .table > thead > tr > th,
.how-we-hear .table > tbody > tr > th,
.how-we-hear .table > tfoot > tr > th,
.how-we-hear .table > thead > tr > td,
.how-we-hear .table > tbody > tr > td,
.how-we-hear .table > tfoot > tr > td {
  padding: 11px;
}

/* how-we-hear ends  */

/* our-workday-tools starts */
.our-workday-tools {
  background: #fbfbfb;
  float: left;
  padding: 90px 0;
  width: 100%;
}

.our-workday-tools .title {
  float: left;
  width: 100%;
}

.our-workday-tools .title h2 {
  color: #feba12;
  margin-bottom: 20px;
  text-align: center;
  text-transform: capitalize;
}

.our-workday-tools .title p {
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 80%;
  text-align: center;
}

.our-workday-tools p {
  font-size: 17px;
  line-height: 160%;
}

.our-workday-tools .details {
  margin-bottom: 30px;
  margin-top: 30px;
}

.our-workday-tools .details img {
  height: 100px;
  margin-bottom: 20px;
  max-width: 100%;
}

.our-workday-tools .details h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

/* our-workday-tools ends */
.iot-dev-services .list-section {
  float: left;
  width: 100%;
}

.iot-dev-services .list-section .list {
  float: left;
  width: 50%;
}

.iot-dev-services .list-section .list:nth-child(2n + 1) {
  clear: both;
}

.iot-dev-services .list-section .list:nth-child(1) .cont,
.iot-dev-services .list-section .list:last-child .cont {
  background: #ff9800;
}

.iot-dev-services .list-section .list .cont {
  background: #3f51b5;
  min-height: 279px;
  padding: 30px;
}

.iot-dev-services .list-section .list .cont h4 {
  color: var(--white);
  margin-bottom: 15px;
}

.iot-dev-services .list-section .list .cont p {
  color: var(--white);
  font-size: 17px;
  line-height: 170%;
}

/* services starts here */
.appmaintenance-services {
  float: left;
  padding: 60px 0 90px;
  width: 100%;
}

.appmaintenance-services .col-lg-4:nth-child(3n + 1) {
  clear: both;
}

.appmaintenance-services .heading {
  margin-bottom: 60px;
}

.appmaintenance-services h2 {
  color: #1890ff;
}

.appmaintenance-services h3 {
  font-size: 22px;
  line-height: 150%;
  margin-bottom: 26px;
  padding-left: 60px;
  position: relative;
  transition: all 0.3s;
}

.appmaintenance-services h3 a {
  color: #444;
}

.appmaintenance-services h3 img {
  background: var(--white);
  border-radius: 10px;
  height: 50px;
  left: 0;
  padding: 7px;
  position: absolute;
  top: 0;
}

.appmaintenance-services .module-list {
  margin-top: 35px;
}

.appmaintenance-services p {
  line-height: 160%;
}

/* services ends here */

/* infrastructure-services starts here */
.infrastructure-services {
  float: left;
  padding: 50px 0 90px;
  width: 100%;
}

.infrastructure-services .details {
  width: 100%;
}

.infrastructure-services .details img {
  display: block;
  margin: 0 auto;
  width: 80%;
}

.infrastructure-services .details p {
  line-height: 200%;
}

/* infrastructure-services ends here */

.documentit-infra-services {
  float: left;
  padding: 90px 0;
  width: 100%;
  h3 {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
  }
  .services-lists {
    float: left;
    width: 100%;
    .list {
      float: left;
      margin-bottom: 30px;
      padding: 0 10px;
      width: 25%;
      &:nth-child(4n + 1) {
        clear: both;
      }
      .details {
        color: #333;
        float: left;
        margin-top: 47px;
        padding-left: 61px;
        position: relative;
        width: 100%;
        i {
          color: #ff9800;
          font-size: 39px;
          left: 0;
          position: absolute;
          top: -14px;
        }
        p {
          font-size: 19px;
        }
      }
    }
  }
}

/* services ends here */

/* our-cloud-process ends here */
.our-cloud-process {
  float: left;
  padding: 90px 0;
  width: 100%;
  h3 {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
  }
  .services-lists {
    float: left;
    width: 100%;
    .list {
      float: left;
      margin-bottom: 30px;
      padding: 0 10px;
      width: 25%;
      &:nth-child(4n + 1) {
        clear: both;
      }
      .details {
        color: #333;
        float: left;
        margin-top: 47px;
        position: relative;
        text-align: center;
        width: 100%;
        i {
          color: #3f51b5;
          display: block;
          font-size: 30px;
          margin-bottom: 17px;
        }
      }
      p {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
}

/* process ends here */

/* our-cloud-process ends here */
.sap-offerings {
  float: left;
  width: 100%;
  h3 {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
  }
  .details {
    float: left;
    width: 100%;
    .list {
      float: left;
      margin-bottom: 30px;
      padding: 0 10px;
      width: 25%;
      &:nth-child(4n + 1) {
        clear: both;
      }
      .cont {
        background: #3f51b5;
        border-radius: 4px;
        color: var(--white);
        float: left;
        margin-top: 47px;
        min-height: 175px;
        padding: 20px;
        position: relative;
        text-align: center;
        width: 100%;
        &:hover {
          background: #1890ff;
        }
        i {
          color: var(--white);
          display: block;
          font-size: 48px;
          margin-bottom: 21px;
        }
        h4 {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

/* process ends here */

/* sap-slider-services ends here */
.sap-slider-services {
  float: left;
  padding: 90px 0;
  width: 100%;
  h3 {
    font-size: 25px;
    margin-bottom: 40px;
    text-align: center;
  }
  .service-list {
    float: left;
    width: 100%;
    .list {
      float: left;
      padding: 10px;
      width: 100%;
    }
    .list,
    .list:focus,
    .list:hover,
    .list:active {
      outline: none;
    }

    .list .cont {
      background: #fbfbfb;
      border-radius: 4px;
      float: left;
      min-height: 200px;
      padding: 20px;
      width: 100%;
    }
  }
}

.sap-slider-services .service-list .list .right,
.sap-slider-services .service-list .list .left {
  float: left;
  padding: 0 6px;
  width: 50%;
}

.sap-slider-services .service-list .list .right img {
  max-width: 100%;
}

.sap-slider-services .service-list .list h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.sap-slider-services .service-list .list p {
  color: #555;
  line-height: 160%;
}

/* process ends here */

/* why infra services starts */
.why-infra-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.why-infra-services h3 {
  font-size: 25px;
  margin-bottom: 39px;
  text-align: center;
}

.why-infra-services .col-sm-6 {
  margin-top: 20px;
}

.why-infra-services h4 {
  color: #505ba8;
  font-size: 22px;
  margin-bottom: 17px;
  text-align: left;
}

.why-infra-services p {
  color: #555;
  line-height: 200%;
}

/* why infra services ends here */

/* cloud-services starts here */
.cloud-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.cloud-services .details {
  width: 100%;
}

.cloud-services .details img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 80%;
}

.cloud-services .details img.square-img {
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 60px;
  border-top-left-radius: 80px;
  border-top-right-radius: 50px;
}

.cloud-services .details p {
  line-height: 200%;
}

.cloud-services .details h3 {
  font-weight: bold;
  line-height: 169%;
  margin-bottom: 20px;
}

.cloud-services .details ul {
  list-style: unset;
}

.cloud-services .details ul li {
  line-height: 200%;
  margin-top: 10px;
}

/* cloud-services ends here */

/* other-consulting-services starts */
.other-consulting-services {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.other-consulting-services h3 {
  font-size: 25px;
  margin-bottom: 39px;
  text-align: center;
  text-transform: capitalize;
}

.other-consulting-services .col-sm-6 {
  margin-top: 20px;
}

.other-consulting-services .content {
  background: #fbfbfb;
  border-radius: 4px;
  min-height: 280px;
  padding: 20px;
}

.other-consulting-services h4 {
  color: #1890ff;
  font-size: 22px;
  margin-bottom: 17px;
  text-align: left;
}

.other-consulting-services p {
  color: #555;
  line-height: 200%;
}

.other-consulting-services ul {
  padding-left: 10px;
}

.other-consulting-services ul li {
  color: #555;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 15px;
  padding-left: 35px;
  position: relative;
  text-align: justify;
}

.other-consulting-services ul li::before {
  color: #505ba8;
  content: "\f05d";
  font-family: FontAwesome;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  left: 0;
  padding-right: 0.5em;
  position: absolute;
  text-decoration: inherit;
  top: 0;
}

/* other-consulting services ends here */

.admin-action-icons {
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.admin-action-icons i {
  background: #fbfbfb;
  border: 0;
  border-radius: 7px;
  color: #333;
  margin-right: 10px;
  outline: none;
  padding: 5px 10px;
}

.admin-action-icons i:last-child {
  margin-right: 0;
}

.main-admin-pannel {
  background: #f9f9f9;
  width: 100%;
}

/* top menu starts here */
.top-nav-bar {
  padding-bottom: 57px;
  width: 100%;
}

.top-nav-bar .navbar {
  background: var(--white);
  border-radius: 0;
  box-shadow: 0 0 10px rgba(99, 99, 99, 0.5);
  z-index: 999;
}

.top-nav-bar .navbar-brand img {
  height: 41px;
  margin-top: 6px;
}

.top-nav-bar .navbar-collapse {
  float: right;
  text-align: right;
  width: 100%;
}

.top-nav-bar .navbar-collapse .navbar-nav {
  float: right;
  margin: 0;
  padding-top: 16px;
}

.top-nav-bar .navbar-collapse a {
  border: 1px solid #c2c2c2;
  border-radius: 27px;
  color: var(--text);
  padding: 5px 20px;
}

.top-nav-bar .dropdown-menu {
  margin: 14px 0 0;
  padding: 14px 0;
}

.top-nav-bar .dropdown-menu a {
  border: 0;
  border-radius: 0;
  color: #070707;
  font-size: 17px;
  padding: 5px 20px;
}

/* top menu ends here */

/* side menu starts here */
.side-menu-bar {
  background: #5661ab;
  box-shadow: 0 0 10px rgba(206, 206, 206, 0.5);
  height: 100%;
  position: fixed;
  top: 57px;
  z-index: 9;
}

.side-menu-bar nav {
  color: var(--text);
}

/* side menu ends here */

/* inner page blogs starts here */
.blog-study {
  float: left;
  padding: 90px 0;
  width: 100%;
}

.blog-study h2 {
  margin-bottom: 45px;
  text-align: center;
}

.blog-study .blog-details h3 {
  font-size: 21px;
  line-height: 164%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-study .blog-details {
  -webkit-box-shadow: 0 2px 17px rgba(62, 62, 62, 0.08);
  box-shadow: 0 2px 17px rgba(62, 62, 62, 0.08);
  float: left;
  margin-top: 20px;
  width: 100%;
}

.blog-study .blog-details p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-height: 164%;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s;
}

.blog-study .blog-details img {
  max-width: 100%;
}

.blog-study .blog-details .cont {
  background: var(--white);
  padding: 16px;
  width: 100%;
}

.blog-study .blog-details .cont button {
  background: 0;
  border: 0;
  color: #1890ff;
  outline: none;
}

/* inner page blogs ends here */

/* center-container starts here */
.admin-center-container {
  height: 100vh;

  /* background: #f9f9f9; */
  padding: 30px 20px 20px 90px;
  width: 100%;
}

.admin-center-container h1 {
  font-size: 25px;
}

/* center-container ends here */

/* admin-bredcrum starts here */
.admin-bredcrum {
  float: left;
  margin-bottom: 20px;
  width: 50%;
}

.admin-bredcrum ul {
  padding: 0;
}

.admin-bredcrum ul li {
  display: inline-block;
}

.admin-bredcrum ul li a {
  color: #0081ff;
  padding: 0 5px;
}

.admin-bredcrum ul li.active a {
  color: var(--text);
}

/* admin-bredcrum ends here */

/* card-design starts here */
.card-design {
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(206, 206, 206, 0.5);
  float: left;
  margin-bottom: 30px;
  padding: 30px;
  width: 100%;
}

.card-design .form-fields {
  float: left;
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;
}

.card-design .form-fields:nth-child(2n + 1) {
  clear: both;
}

.card-design .form-fields.full-width:nth-child(2n + 1) {
  clear: both;
}

.card-design .form-fields.full-width {
  width: 100%;
}

.card-design .form-fields label {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.card-design .form-fields input[type="text"] {
  background: var(--white);
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  color: var(--text);
  outline: none;
  padding: 10px 20px;
  width: 100%;
}

.card-design .form-fields input[type="file"] {
  background: var(--white);
  border: 1px dashed #e5e5e5;
  border-radius: 7px;
  color: var(--text);
  outline: none;
  padding: 10px 20px;
  width: 100%;
}

.card-design button {
  background: rgb(0, 129, 255);
  border: 0;
  border-radius: 7px;
  color: var(--white);
  outline: none;
  padding: 7px 20px;
}

.card-design button i {
  margin-right: 6px;
}

/* .card-design .form-fields .quill{
	min-height: 100px;
	height: 100px;
} */

/* card-design ends here */

/* admin-title starts */
.admin-title {
  float: left;
  margin-bottom: 14px;
  width: 100%;
}

.admin-title h1 {
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* admin-title ends */

/* admin-faq starts here */
.admin-faq {
  float: left;
  width: 100%;
}

.admin-faq .add-button {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

/* admin-faq ends here */

/* add-faqs starts here */
.add-faqs {
  display: block;
  width: 100%;
}

.add-faqs .form-fields {
  display: block;
  margin-bottom: 20px;
  padding: 0 10px;
  width: 100%;
}

.add-faqs .form-fields:nth-child(2n + 1) {
  clear: both;
}

.add-faqs .form-fields.full-width:nth-child(2n + 1) {
  clear: both;
}

.add-faqs .form-fields.full-width {
  width: 100%;
}

.add-faqs .form-fields label {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.add-faqs .form-fields input[type="text"] {
  background: var(--white);
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  color: var(--text);
  outline: none;
  padding: 10px 20px;
  width: 100%;
}

.add-faqs .form-fields input[type="file"] {
  background: var(--white);
  border: 1px dashed #e5e5e5;
  border-radius: 7px;
  color: var(--text);
  outline: none;
  padding: 10px 20px;
  width: 100%;
}

.add-faqs button.btn-primary {
  background: rgb(0, 129, 255);
  border: 0;
  border-radius: 7px;
  color: var(--white);
  outline: none;
  padding: 7px 20px;
}

.add-faqs button.btn-primary i {
  margin-right: 6px;
}

/* add-faqs ends here */

/* blog-table starts here */
.blog-table {
  float: left;
  width: 100%;
}

.blog-table tbody tr {
  border-bottom: 1px solid #dcdcdc;

  /* background: #f9f9f9; */
  margin-bottom: 10px !important;
}

.blog-table tbody tr:last-child {
  border-bottom: 0;
}

.blog-table tbody tr td.title {
  width: 189px;
}

.blog-table .ReactTable .description {
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 189px;
}

.blog-table .ReactTable img {
  height: 50px;
}

.blog-table .ReactTable button {
  background: rgb(249, 249, 249);
  border: 0;
  border-radius: 7px;
  color: #0081ff;
  margin-right: 10px;
  outline: none;
  padding: 3px 9px;
}

.blog-table tbody tr td {
  border-top: 0;
}

/* blog-table ends here */

.admin-faq-search {
  float: left;
  width: 50%;
}

.admin-faq-search form {
  display: block;
  margin: 0 auto;
  width: 60%;
}

.admin-faq-search form .form-fields {
  position: relative;
  width: 100%;
}

.admin-faq-search form .form-fields input[type="text"] {
  background: var(--white);
  border: 1px solid #ebebeb;
  border-radius: 27px;
  outline: none;
  padding: 9px 43px 9px 16px;
  width: 100%;
}

.admin-faq-search form .form-fields button {
  background: 0;
  border: 0;
  outline: none;
  position: absolute;
  right: 15px;
  top: 9px;
}

/* admin-faq-search ends here */

.Modal-popup.show {
  display: block !important;
  opacity: 1 !important;
}

.modal-backdrop.fade {
  opacity: 0.8;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 19%);
  -ms-transform: translate(0, 19%);
  -o-transform: translate(0, 19%);
  transform: translate(0, 19%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.marr-top {
  margin-top: 119px !important;
  text-align: center;
}

/* mobile-menu starts here */
.mobile-menu {
  display: none;
  float: right;
  padding: 11px;
  text-align: right;
  width: 29%;
}

.mobile-menu i {
  color: #0b0c26;
  cursor: pointer;
  font-size: 22px;
}

.mobile-menu i:hover {
  color: var(--primary);
}

/* Extra large devices  */
/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (max-width: 1200px) {
  .documentit-banner video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-55%) translateY(-68%);
    transform: translateX(-55%) translateY(-68%);
    width: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .documentit-solution .solutions-list .list:nth-child(2n + 1) {
    clear: both;
  }

  .documentit-solution .solutions-list .list:nth-child(3n + 1) {
    clear: unset;
  }

  .workday-consulting .list-details .list {
    width: 33.333%;
  }

  .main-navigation .right-menu {
    display: none;
  }

  .main-navigation .center-menu {
    width: 100%;
  }

  .main-navigation .center-menu ul li {
    text-align: center;
    width: 100%;
  }

  .main-navigation .right-action-button {
    text-align: center;
    width: 100%;
  }

  .mobile-menu {
    display: block;
  }

  .main-navigation {
    display: block;
    float: left;
  }

  .documentit-products .product-details {
    padding: 22px 0 70px;
  }

  .it-security-needs .needs ul li {
    width: 33.333%;
  }

  .documentit-infra-services .services-lists .list {
    width: 50%;
  }

  .sap-offerings .details .list {
    width: 33.333%;
  }

  .sap-offerings .details .list .cont {
    margin-top: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .features-sliders {
    padding: 62px 0 90px;
  }

  .sap-slider-services .service-list .list .right,
  .sap-slider-services .service-list .list .left {
    width: 100%;
  }

  .sap-slider-services .service-list .list .right img {
    margin-top: 20px;
  }

  .for-more-information h4 {
    font-size: 26px;
    line-height: 177%;
    margin-bottom: 11px;
  }

  .clinical-trial .advantages-list .list {
    margin-top: 18px;
    width: 50%;
  }

  .we-verify-students .list {
    margin-top: 16px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .workday-consulting .list-details .list {
    width: 50%;
  }

  .our-workday-tools .title h2,
  .how-we-hear .details thead tr th,
  .invest-workday .details h4,
  .workday-consulting h2 {
    font-size: 22px;
    line-height: 150%;
  }

  .iot-dev-services .list-section .list {
    float: left;
    width: 100%;
  }

  .iot-dev-services .list-section .list:nth-child(1) .cont,
  .iot-dev-services .list-section .list:nth-child(3) .cont {
    background: #ff9800;
  }

  .iot-dev-services .list-section .list:last-child .cont {
    background: #3f51b5;
  }

  .iot-dev-services .needs ul li {
    width: 50%;
  }

  .get-a-quote {
    text-align: center;
  }

  .heading h2 {
    font-size: 25px;
  }

  .breadcrum p {
    font-size: 20px;
    width: 100%;
  }

  .breadcrum h1 {
    font-size: 33px;
  }

  .breadcrum span {
    text-align: center;
    width: 100%;
  }

  .features-slide .list .left-cont,
  .features-slide .list .right-cont {
    width: 100%;
  }

  .features-slide .list .left-cont {
    padding: 20px;
  }

  .features-slide .list .right-cont {
    padding: 0 10px;
  }

  .features-slide .list .right-cont {
    margin-top: 20px;
  }

  .slick-next,
  .slick-prev {
    z-index: 999;
  }

  .slick-prev {
    left: -10px;
  }

  .slick-next {
    right: -9px;
  }

  .features-slide .list .left-cont {
    min-height: auto;
  }

  .locations img.locationss {
    width: 100%;
  }

  .locations h2 {
    font-size: 25px;
  }

  .iot-dev-services h2,
  .tech-services h2,
  .blog-study h2,
  .blockchain-platform h2,
  .blockchain-services h2,
  .how-its-helps-business h2,
  .it-security-needs h2,
  .why-infra-services h3,
  .documentit-module-points h2,
  .clinical-trial .title h2,
  .we-verify-students h4 {
    font-size: 25px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .machinelearning-services h2 {
    font-size: 25px;
    line-height: 150%;
  }

  .machinelearning-services p.title {
    width: 100%;
  }

  .documentit-products .product-details h1 {
    font-size: 27px;
    margin-bottom: 23px;
  }

  .it-security-needs {
    padding: 43px 0;
  }

  .it-security-needs .needs ul li {
    width: 50%;
  }

  .documentit-products .product-details p span {
    font-size: 20px;
  }

  .call-back-popup .popup {
    width: 100%;
  }

  .appmaintenance-services h2 {
    font-size: 25px;
    line-height: 150%;
    text-align: center;
  }

  .appmaintenance-services h3 {
    font-size: 19px;
  }

  .documentit-infra-services .services-lists .list {
    width: 100%;
  }

  .sap-offerings .details .list,
  .blog-grids .blog-lists .list {
    width: 50%;
  }

  .sap-offerings .details .list .cont {
    margin-top: 23px;
    min-height: auto;
    padding: 20px;
  }

  .cloud-services .details img.square-img,
  .cloud-services .details img.square-img {
    margin-bottom: 20px;
  }

  .locations img {
    width: 80%;
  }

  .documentit-products {
    padding: 90px 0 50px;
  }

  .documentit-products .product-details img {
    margin-top: 20px;
    width: 100%;
  }

  .login .content .center-content .login-sections .left,
  .login .content .center-content .login-sections .right {
    border-radius: 0;
    float: left;
    width: 100%;
  }

  .login .content .center-content .login-sections {
    display: block;
  }

  .login .content .center-content {
    padding: 30px 0;
  }

  .login .content .center-content .login-sections {
    width: 100%;
  }
}

/* Extra small devices (phones, 560px and down) */
@media only screen and (max-width: 560px) {
  .footer .links {
    min-height: auto;
  }

  .footer .col-lg-3.col-md-4.col-sm-6.col-xs-6 {
    width: 100%;
  }

  .we-verify-students .list {
    margin-top: 16px;
    width: 100%;
  }

  .blog-grids .blog-lists .list {
    width: 100%;
  }

  .locations img {
    width: 96%;
  }

  .reorg-features h3,
  .documentit-module-points h3 {
    padding-top: 25px;
  }
}

/* Extra small devices (phones, 480px and down) */
@media only screen and (max-width: 480px) {
  .documentit-solution .solutions-list .list {
    width: 100%;
  }

  .documentit-solution .solutions-list .list:nth-child(2n + 1) {
    clear: unset;
  }

  .workday-consulting .list-details .list {
    width: 100%;
  }

  .iot-dev-services .needs ul li {
    width: 100%;
  }

  .fun-facts .col-lg-3.col-md-4.col-sm-6.col-xs-6,
  .fun-facts .col-xs-6 {
    width: 100%;
  }

  .contact-us-page .form-fields {
    margin-bottom: 19px;
    width: 100%;
  }

  .contact-us-page .form-fields input[type="button"] {
    width: 100%;
  }

  .it-security-needs .needs ul li {
    width: 100%;
  }

  .call-back-popup .popup .cont img {
    display: none;
  }

  .call-back-popup .popup .cont {
    width: 100%;
  }

  .sap-offerings .details .list {
    width: 100%;
  }

  .sap-offerings .details .list {
    margin-bottom: 0;
  }

  .clinical-trial .advantages-list .list {
    width: 100%;
  }
}

/* --Responsive ends here-- */

// view-contact-details starts
.view-contact-details,
.enter-testimonials {
  padding: 20px;
  width: 100%;
}

.view-contact-details .form-fields,
.enter-testimonials .form-fields {
  margin-bottom: 20px;
  width: 100%;
}

.view-contact-details .form-fields span,
.enter-testimonials .form-fields label {
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
}

// view-contact-details ends here
.challenges-integration {
  line-height: 180%;
}

.achive-business-goal {
  @include section;
  background-color: #0935c1;
  background-image: url("../assets/images/pattern/pattern-left.png");
  @include pattern-background;
  h2,
  p {
    color: var(--white);
  }
  h2 {
    font-size: 30px;
  }
}

// shapes background starts
.circle-shape {
  position: relative;
  &::before {
    @include bgimgContBottom;
    opacity: 0.4;
    background: url("../assets/images/shapes/circle.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
.circle-shape-blue {
  position: relative;
  &::before {
    @include bgimgContBottom;
    opacity: 0.4;
    background: url("../assets/images/shapes/circle-blue.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
.circle-shape-gray {
  position: relative;
  &::before {
    @include bgimgContBottom;
    opacity: 0.4;
    background: url("../assets/images/shapes/circle-gray.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
.m-shape {
  position: relative;
  &::before {
    @include bgimgCont;
    background-image: url(../assets/images/shapes/m-shape.svg);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 45%;
    opacity: 0.5;
  }
}
// shapes background ends

// career job list
.career-job-list {
  background: var(--white);
  padding: 60px 0;
  width: 100%;
  position: relative;
  &::before {
    @include bgimgContBottom;
    background-image: url("../assets/images/inner-pages-1.png");
    background-position: bottom right;
    opacity: 0.7;
  }
  .career-list {
    float: left;
    margin-top: 20px;
    width: 100%;
    .list {
      float: left;
      margin-bottom: 19px;
      padding: 0 10px;
      text-align: left;
      width: 33.333%;
      @media (max-width: 900px) {
        width: 50%;
      }
      @media (max-width: 500px) {
        width: 100%;
        padding: 0;
      }
      &:nth-child(3n + 1) {
        clear: both;
      }
      a {
        display: inline-block;
      }
      h4 {
        font-size: 19px;
        line-height: 150%;
        text-transform: capitalize;
      }
      p {
        font-size: 16px;
        line-height: 168%;
        margin-top: 18px;
        color: var(--text);
      }
      .details {
        width: 100%;
        box-shadow: 0 2px 12px#cbcbcb;
        border-radius: 10px;
        background: var(--white);
        padding: 20px;
        @media (max-width: 500px) {
          min-height: auto;
        }
      }
    }
  }
}

.job-descriptions {
  width: 100%;
  padding: 90px 0;
  .profile-data {
    padding: 20px;
    background: var(--light-blue);
    border-radius: 6px;
    h3 {
      color: var(--text);
      font-size: 22px;
    }
    ul {
      list-style: circle;
      li {
        padding-bottom: 10px;
      }
    }
  }

  h4 {
    margin-top: 20px;
    a {
      font-size: 22px;
    }
  }
}

// why-choose-us
.dotted-card-design {
  padding: 30px;
  border: 1px dashed #c9c9c9;
  border-radius: 16px;
}

.list {
  padding: 0;
  margin: 0;
  position: relative;
  li {
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--primary);
    }
  }
}

// contact
.contact-card {
  background-color: var(--white);
  border-radius: 0.5rem;
  // box-shadow: 1px 1px 16px #00000014;
  padding: 2rem;
  ul {
    list-style: none;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 0;
    li {
      a {
        color: #343434;
        display: inline-block;
        margin-bottom: 10px;
        span {
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}
