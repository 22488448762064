/* dynex-features starts */
.dynex-features{
    width:100%;
    float:left;
    padding: 70px 0;
    background: #f9f9f9;
  }
  .dynex-features h2{
    text-align: center;
    margin-bottom: 64px;
  }
  .dynex-features .col-lg-4:nth-child(3n+1){
    clear: both;
  }
  .dynex-features .details{
    width: 100%;
    float: left;
    color: #333;
    position: relative;
  }
  .dynex-features .details i{
    position: absolute;
    top: -14px;
    left: 0;
    font-size: 39px;
    color: #FF9800;
  }
  .dynex-features .details p{
    font-size: 19px;
    background: #fff;
    border-radius: 10px;
    margin-bottom:16px;
    padding: 25px 20px;
    text-align: center;
    -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.08);
    box-shadow: 0 2px 8px rgba(0,0,0,.08)
  }
  .dynex-features .details p:first-letter{
    text-transform: capitalize;
  }
  /* dynex-features ends here */
  