.who-do-we-help {
  padding: 60px 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
  img {
    width: 66%;
    margin-bottom: 20px;
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  p {
  }
}

// .roles-transformation starts
.roles-transformation {
  width: 100%;
  background: #e8ebed;
  padding: 90px 0;
  h2,
  p {
    text-align: center;
  }
  h2 {
    color: #000;
  }
  .slick-initialized .slick-slide,
  .slick-slide .list {
    outline: none;
    text-decoration: none;
  }
  .slick-next,
  .slick-prev {
    bottom: 50%;
  }
  .slick-list {
    margin: 20px 0;
  }
  .slick-dots {
    bottom: -38px;
  }
  .list {
    padding: 10px 10px;
    .details {
      width: 100%;
      background: #fff;
      border-radius: 6px;

      padding: 25px;
      min-height: 300px;
      // box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease 0s;
      @media (max-width: 700px) {
        min-height: auto;
      }
    }
    h4,
    p {
      text-align: left;
    }
    h4 {
      margin-bottom: 20px;
    }
  }
}

// .roles-transformation ends

// .hr-transformation starts here
.hr-transformation {
  width: 100%;
  padding: 90px 0;
}
.top-section {
  text-align: center;
}
.process-steps {
  padding: 30px;
  background: #fbfbfb;
  margin-top: 30px;
  font-size: 16px;
  border-radius: 5px;
  .ant-tabs,
  .ant-tabs-tab {
    font-size: 16px;
  }
  .ant-tabs-tab {
    font-weight: 600;
  }
  ul {
    list-style: circle;
    li {
      margin-top: 10px;
    }
  }
}
