/* Chrome, Safari, Edge, Opera */
:where(.css-dev-only-do-not-override-17seli4).ant-btn,
:where(.css-dev-only-do-not-override-17seli4).ant-col {
  font-family: var(--inter);
}
:where(.css-dev-only-do-not-override-17seli4).ant-col {
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}
a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    color: var(--primary);
    text-decoration: none;
  }
}

// Antd Button styles
a.ant-btn {
  padding-top: 4px !important;
  line-height: 30px;
}
.ant-btn {
  &a {
    padding-top: 4px !important;
  }
  .arrow-right {
    rotate: -40deg;
  }
}
.ant-btn,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  min-height: 40px;
  border-radius: 3px;
  &.ant-btn-sm {
    min-height: 30px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.ant-btn-primary,
.ant-btn-default,
.ant-btn-secondary {
  border-radius: 6px;
  box-shadow: 0;
}
.ant-btn-primary,
.ant-btn-primary:active {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
  &.ant-btn-background-ghost {
    color: var(--primary);
    border-color: var(--primary);
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  &.ant-btn-background-ghost {
    color: var(--primary);
  }
}
.ant-btn-secondary,
.ant-btn-secondary:active {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
  &.ant-btn-background-ghost {
    color: var(--secondary);
  }
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--white);
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  &.ant-btn-background-ghost {
    color: var(--secondary);
  }
}
.ant-btn-default {
  color: var(--secondary);
  border: 1.5px solid var(--line);
  background-color: var(--white);
  &.white {
    border: 0;
  }
}

.ant-btn-default:hover,
.ant-btn-default:focus {
  border-color: var(--line);
  color: var(--secondary-hover);
}

// tags
.ant-tag {
  padding: 5px 14px;
  font-size: 12px;
  border-radius: 20px;
  border: 0;
  @media (max-width: 500px) {
    padding: 4px 8px;
    font-size: 10px;
    margin-right: 4px;
  }
}

// collapsible items
.ant-collapse-ghost {
  .ant-collapse-item {
    border-bottom: 1px solid var(--dark-line);
  }
}
.ant-collapse {
  .ant-collapse-item {
    background-color: transparent;
    .ant-collapse-header,
    .ant-collapse-content .ant-collapse-content-box {
      padding: 12px 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-top: 0;
      }
    }
    .ant-collapse-header {
      .ant-collapse-arrow {
        width: 17px;
        height: 17px;
        color: #686868;
      }
    }
  }
}

// message popup
.tawk-chat-bubble.tawk-message-preview-chat-bubble {
  font-size: 13px;
  line-height: 30px;
}

// modal box
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

// ant-input
.ant-input {
  padding: 8px 11px;
  &:hover,
  &:focus,
  &:active {
    border-color: #d9d9d9;
    box-shadow: none;
  }
}

// .sticky-top
.sticky-top {
  z-index: 9 !important;
}
