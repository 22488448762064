@import "../../style/mixin.scss";
.job-banner {
  align-items: center;
  min-height: 447px;
  padding: 213px 44px 51px 32px;
  position: relative;
  width: 100%;
  background: #f7f7f7;
  &::before {
    @include bgimgCont;
    background-image: url(../../assets/images/shapes/jobs-banner.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
  }
}
