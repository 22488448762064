// tools-inner-section starts here
.tools-inner-section {
  width: 100%;
  padding: 60px 0;
  position: relative;
  .row {
    position: relative;
    z-index: 9;
  }
  h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .details {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 10px #cbcbcb;
    transition: all 0.3s ease 0s;
    padding: 25px;
    min-height: 327px;
    position: relative;
    z-index: 9;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      margin-bottom: 20px;
      min-height: auto;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 22px;
      color: #000;
    }
    a {
      display: inline-block;
      margin-top: 20px;
      position: absolute;
      bottom: 30px;
      @media (max-width: 500px) {
        margin-top: 20px;
        position: relative;
        bottom: 0;
      }
    }
  }
}
