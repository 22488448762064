.our-team {
  .team-list {
    padding: 1rem;
    border-radius: 10px;
    background: #fff9ea;
    text-align: center;

    @media (max-width: 400px) {
      text-align: center;
    }

    img {
      width: 70px;
      height: 70px;
      margin-bottom: 0.5rem;
      border-radius: 100%;
      border: 2px solid #f4f4f4;
    }

    h3 {
      font-size: 18px;
      line-height: 130%;
    }

    &:hover {
      border-color: var(--primary);
    }

    .location {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 400px) {
        justify-content: center;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-bottom: 0;
        border: 0;
      }
    }
  }
}
