@import "../../style/mixin.scss";
.bigdata-healthcare-benefits {
  padding: 90px 0;
  background-color: var(--primary);
  background-image: url("../../assets/images/bigdata-analytics-healhcare.jpg");
  width: 100%;
  float: left;
  @include background-position;
  .details {
    @include cardview-dark;
  }
  h3 {
    font-size: 30px;
  }
  h3,
  p {
    color: var(--white);
  }
  p {
  }
}
