@mixin HeadingStyle {
  color: var(--primary);
  font-size: 20px;
  margin-bottom: 14px;
  text-transform: uppercase;
}

@mixin imageSelectnone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
@mixin beforeContent {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@mixin background-position {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@mixin pattern-background {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 300px;
}
@mixin pattern-background-left {
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 300px;
}
@mixin cardview-dark {
  padding: 50px;
  background: rgba(30, 79, 237, 0.9);
  border-radius: 3px;
  @media (max-width: 500px) {
    padding: 25px;
  }
}
@mixin section {
  padding: 90px 0;
  width: 100%;
}
@mixin card-view {
  padding: 25px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  @media (max-width: 500px) {
    padding: 25px;
  }
}

@mixin bgimgCont {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
}
@mixin bgimgContBottom {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
}

@mixin flexDesign($display: flex, $direction: row, $align: center) {
  display: $display;
  flex-direction: $direction;
  align-items: $align;
}

// common fonts and styles for application
@mixin heading1 {
  font-size: 40px;
  line-height: 113%;
}
@mixin font1 {
  font-weight: var(--semibold);
  line-height: 28px;
}

@mixin font2 {
  font-size: 16px;
  font-weight: var(--regular);
  line-height: 24px;
}

@mixin font3 {
  font-size: 16px;
  font-weight: var(--medium);
  line-height: 24px;
}

@mixin font4 {
  font-size: 14px;
  font-weight: var(--regular);
  line-height: 25px;
}

@mixin font5 {
  font-size: 14px;
  font-weight: var(--semibold);
  line-height: 20px;
}

@mixin font6 {
  font-size: 12px;
  font-weight: var(--regular);
  line-height: 18px;
}

// text ellipse
@mixin textEllipse($line: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin borderRadiusTLR($left: 10px, $right: 10px) {
  border-top-left-radius: $left;
  border-top-right-radius: $right;
}
