@import "../../style/mixin.scss";
.challenge-we-facing {
  @include section;
  background-image: url("../../assets/images/data-analytics.webp");
  @include background-position;
  color: var(--white);
  position: relative;
  &::before {
    @include beforeContent;
    background: var(--text);
    opacity: 0.6;
  }
  ul {
    list-style: circle;
  }
  li,
  p {
  }
  h2,
  h4 {
    color: var(--white);
  }
}

.digital-transformation {
  @include section;
  background: url("../../assets/images/pattern/pattern.png");
  @include pattern-background;
  .heading {
    margin-bottom: 20px;
    text-align: center;
  }
  ul {
    list-style: circle;
    padding-left: 22px;
  }
  .card-view {
    @include card-view;
    min-height: 332px;
    margin-bottom: 15px;
    h4 {
      font-size: 20px;
    }
  }
}

.scaleup-business {
  @include section;
  background-color: #0935c1;
  background-image: url("../../assets/images/pattern/pattern-left.png");
  @include pattern-background-left;
  .heading {
    margin-bottom: 20px;
    text-align: center;
    h1,
    p {
      color: var(--white);
    }
  }
  .card-view {
    @include card-view;
    margin-bottom: 15px;
    min-height: 469px;
    @media (max-width: 900px) {
      min-height: auto;
    }
    h4 {
      font-size: 20px;
    }
    img {
      height: 100px;
    }
  }
}

.bigdata-benefits {
  @include section;
  .heading {
    margin-bottom: 30px;
    text-align: center;
  }
  .ant-col {
    h4 {
      font-size: 20px;
    }
    .benefits {
      margin-bottom: 25px;
    }
  }
}
