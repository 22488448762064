/*!
 * Bootstrap v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// Limited
// Define custom theme colors
$theme-colors-rgb: (
  "primary": var(--primary),
  "secondary": var(--secondary),
  // Add other colors as needed
);

// Import Bootstrap's SCSS
@import "~bootstrap/scss/bootstrap";

// Your custom styles here

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/utilities";
